export var validatePhoneNumber = function (phoneNumber) {
    if (validateMainlandPhoneNumber(phoneNumber)) return true;
    // 香港
    if (validateHongKongPhoneNumber(phoneNumber)) return true;
    // 澳门
    if (validateMacauPhoneNumber(phoneNumber)) return true;
    // 台湾
    if (validateTaiwanPhoneNumber(phoneNumber)) return true;
    return false; // 如果都不匹配，则返回false
};
export default validatePhoneNumber;

function validateMacauPhoneNumber(phoneNumber) {
    var re = /^6\d{7}$/;
    return re.test(phoneNumber);
}

function validateTaiwanPhoneNumber(phoneNumber) {
    var re = /^09\d{8}$/;
    return re.test(phoneNumber);
}
function validateMainlandPhoneNumber(phoneNumber) {
    var re = /^1[3-9]\d{9}$/;
    var re2 =  /^\+861[3-9]\d{9}$/;

    return re.test(phoneNumber) || re2.test(phoneNumber);
}

function validateHongKongPhoneNumber(phoneNumber) {
    // 8位或9位数字，不含区号
    var reWithoutAreaCode = /^[2-35-9]\d{6,7}$/;
    // 852开头，包含区号，可能包含+或00前缀
    var reWithAreaCode = /^(\+?852|00852)?-?[2-35-9]\d{6,7}$/;
    return reWithoutAreaCode.test(phoneNumber) || reWithAreaCode.test(phoneNumber);
}

