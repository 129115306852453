var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"number-box"},[_c('div',{class:{ minus: true, disabled: _vm.disabled || _vm.inputVal <= _vm.min },style:({
      background: _vm.bgColor,
      height: _vm.inputHeight + 'px',
      color: _vm.color,
    }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.btnTouchStart('minus')}}},[_c('div',{style:({fontSize: _vm.size + 'px'})},[_vm._v("-")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],class:{ 'number-input': true, 'input-disabled': _vm.disabled },style:({
      color: _vm.color,
      fontSize: _vm.size + 'px',
      background: _vm.bgColor,
      height: _vm.inputHeight + 'px',
      width: _vm.inputWidth + 'px',
    }),attrs:{"disabled":_vm.disabledInput || _vm.disabled,"type":"text"},domProps:{"value":(_vm.inputVal)},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"input":function($event){if($event.target.composing)return;_vm.inputVal=$event.target.value}}}),_c('div',{staticClass:"plus",class:{ disabled: _vm.disabled || _vm.inputVal >= _vm.max },style:({
      background: _vm.bgColor,
      height: _vm.inputHeight + 'px',
      color: _vm.color,
    }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.btnTouchStart('plus')}}},[_c('div',{style:({fontSize: _vm.size + 'px'})},[_vm._v("+")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }