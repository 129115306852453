<template>
  <div id="app">
    <router-view  />
  </div>
</template>
<script>
import { getConfig } from "@/api/index.js";
export default {
  data() {
    return {
      
    };
  },
  created() {
    
    if (this.isMobile()) {
      window.location.href = 'http://m.genghis.hk';
  } else {
    console.log('访问来自桌面');
  }
  // var header_config = localStorage.getItem("header_config");
  // if(!header_config){
  //   getConfig().then(res=>{
  //     localStorage.setItem("header_config",JSON.stringify(res.data.header_config))
  //     localStorage.setItem("footer_config",JSON.stringify(res.data.footer_config))
  //     localStorage.setItem("nav_config",JSON.stringify(res.data.nav_config))
  //     localStorage.setItem("link_config",JSON.stringify(res.data.link_config))
  //     window.location.reload()
  //   })
  // }
   

  },
  methods: {
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
  }
};
</script>
<style>
.el-input__inner:focus {
  border-color: initial!important;;
}
</style>

<style lang="scss">
.app-max-container{
  max-width: 1240px;
  width: 1240px;
  margin: 0 auto;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
body,
html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  /* Ensure padding and borders are included in element sizes */
}
a {
  color: inherit; /* 继承父元素的文字颜色 */
  text-decoration: none; /* 移除下划线 */
}
 
/* 鼠标悬停时显示下划线 */
a:hover {
  color: inherit; /* 继承父元素的文字颜色 */
  text-decoration: none; /* 移除下划线 */
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Custom Styles */
body {
  background: white;
  color: black;
  font-family: 'Open Sans', sans-serif;
}
.el-loading-spinner {
  .el-loading-text{
    color: #000!important;
  }
  .el-icon-loading{
    font-size: 48px;
    color:#000 ;
  }
}


</style>