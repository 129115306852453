<template>
  <div class="">
    <appHeader></appHeader>
    <appNav></appNav>
    <div class="app-max-container banner_container">
      <img :src="banner" class="banner" />
    </div>
    <div class="one-bg">
      <div class="app-max-container">
        <div class="title_box">
          <div class="container_item_title">
            <p class="title">{{ one.title }}</p>
            <span class="title_en">{{ one.title_en }}</span>
          </div>
        </div>
        <div class="item_box_list">
          <div class="item_box item_info_box" :style="'background-image: url(' + one.info.image + ')'">
            <p class="item_info_box_desc">{{ one.info.desc }}</p>
          </div>
          <div class="item_box item_box_for" v-for="item in one.lst">
            <img class="item_box_img" :src="item.image" />
            <p class="item_title">{{ item.title }}</p>
            <p class="item_desc">{{ item.desc }}</p>
          </div>
        </div>

        <div class="tier">
          <div class="tier_title">
            {{ one.tier.title }}
          </div>
          <div class="tier_desc">
            {{ one.tier.desc }}
          </div>
          <div class="tier-list">
            <div class="tier-list-item" v-for="item in one.tier.lst">
              <div class="title">
                <span>{{ item.title }}</span>
              </div>
              <p class="desc">{{ item.desc }}</p>
              <p class="desc">{{ item.desc_two }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-wihte"></div>
    <div class="address" :style="'background-image: url(' + address_image.address_bg + ')'">
      <div class="app-max-container">
        <div class="address_info">
          <div class="address_lfet">
            <div class="address_mpa">
              <iframe
                width="540"
                height="280"
                style="border: 0"
                loading="lazy"
                allowfullscreen
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJBbn1AgD7AzQRWiroaW-HbR4&key=AIzaSyBCP-1vmJv_tAEGZaiabaju_Je4AklgJGk"></iframe>
              <!-- <div id="xianggang" style="width: 540px; height: 280px"></div> -->
            </div>
            <!-- <div class="address_mpa">
              <div id="zhuhai" style="width: 540px; height: 228px;"></div>
            </div> -->
          </div>
          <div class="address_right">
            <template v-for="item in address">
              <div class="address_list_item" v-if="item.title">
                <p class="title">{{ item.title }}</p>
                <p class="desc">{{ item.desc }}</p>
                <p class="desc">{{ item.desc_two }}</p>
                <p class="desc">{{ item.desc_three }}</p>
                <p class="desc">{{ item.desc_four }}</p>
              </div>
            </template>

            <div class="address_img">
              <img class="address_logo" :src="address_image.logo" />
              <!-- <div class="address_img_msg">
                <a v-for="item in address_image.other_img_info" href="">
                  <img class="address_other_img" :src="item.image" />
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="other" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
      <div class="app-max-container">
        <div class="other-container">
          <p class="title">{{ other.title }}</p>
          <div class="other-list_box" ref="other_list_box">
            <div class="other-list" ref="other_list">
              <div class="other-item" v-for="item in other.lst" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
                <template v-if="item.type == 'text'">
                  <!-- :class="item.color == 'blue'?'blue':''" -->
                  <div class="other-item-info bg-color" v-bind:style="{ backgroundColor: item.bg_color, color: item.color }">
                    <p class="other-item-info-title">{{ item.title }}</p>
                    <p class="other-item-info-desc">{{ item.desc }}</p>
                    <a class="other_btn" :href="item.link">
                      <span class="other_btn_span">{{ item.link_text }}</span>
                    </a>
                  </div>
                </template>
                <template v-if="item.type == 'image'">
                  <img class="other-item-img" :src="item.image" />
                </template>
              </div>
            </div>
            <div class="other-list" ref="other_list">
              <div class="other-item" v-for="item in other.lst" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
                <template v-if="item.type == 'text'">
                  <!-- :class="item.color == 'blue'?'blue':''" -->
                  <div class="other-item-info bg-color" v-bind:style="{ backgroundColor: item.bg_color, color: item.color }">
                    <p class="other-item-info-title">{{ item.title }}</p>
                    <p class="other-item-info-desc">{{ item.desc }}</p>
                    <a class="other_btn" :href="item.link">
                      <span class="other_btn_span">{{ item.link_text }}</span>
                    </a>
                  </div>
                </template>
                <template v-if="item.type == 'image'">
                  <img class="other-item-img" :src="item.image" />
                </template>
              </div>
            </div>
            <div class="other-list" ref="other_list">
              <div class="other-item" v-for="item in other.lst" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
                <template v-if="item.type == 'text'">
                  <!-- :class="item.color == 'blue'?'blue':''" -->
                  <div class="other-item-info bg-color" v-bind:style="{ backgroundColor: item.bg_color, color: item.color }">
                    <p class="other-item-info-title">{{ item.title }}</p>
                    <p class="other-item-info-desc">{{ item.desc }}</p>
                    <a class="other_btn" :href="item.link">
                      <span class="other_btn_span">{{ item.link_text }}</span>
                    </a>
                  </div>
                </template>
                <template v-if="item.type == 'image'">
                  <img class="other-item-img" :src="item.image" />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <appLink></appLink>

    <appFooter></appFooter>
  </div>
</template>

<script>
import { getAbout } from "@/api/index.js";
import appHeader from "@/components/header.vue";
import appFooter from "@/components/footer.vue";
import appNav from "@/components/nav.vue";
import appLink from "@/components/link.vue";

export default {
  components: {
    appHeader,
    appFooter,
    appNav,
    appLink
  },
  data() {
    return {
     "banner": "https:\/\/api.genghis.hk\/uploads\/images\/20240515\/20240515161744cd9415528.png",
     "one": {
            "title": "線上詢價",
            "title_en": "ONLINE ORDERING",
            "info": {
                "image": "https:\/\/api.genghis.hk\/uploads\/images\/test\/shutterstock_2167717545.png",
                "desc": "我們的產品範圍廣泛，品質優良，價格合理，並提供可持續發展的建築解決方案，以滿足當今及未來的需求。請聯繫我們，讓我們為您的項目提供最佳的服務和支持。"
            },
            "lst": [
                {
                    "image": "https:\/\/api.genghis.hk\/uploads\/images\/test\/shutterstock_2156727891.png",
                    "desc": "在線填寫詢價單或上傳 Excel表格，提出具體需求。",
                    "title": "填寫詢價單"
                },
                {
                    "image": "https:\/\/api.genghis.hk\/uploads\/images\/test\/shutterstock_2143155369.png",
                    "desc": "我們通過智能辨識提供詳細報價。",
                    "title": "智能報價"
                },
                {
                    "image": "https:\/\/api.genghis.hk\/uploads\/images\/test\/shutterstock_2337135943.png",
                    "desc": "客服團隊與客戶確定訂購項目與數量以及支付方式",
                    "title": "支付確認"
                },
                {
                    "image": "https:\/\/api.genghis.hk\/uploads\/images\/test\/shutterstock_2179439987.png",
                    "desc": "按時將貨物送達客戶指定",
                    "title": "貨物送達"
                }
            ],
            "tier": {
                "title": "3-TIER 採購",
                "desc": "Genghis 擁有自設貨倉，並與主要供應鏈倉互通，確保貨源充足且供應穩定。我們與本地各大物流公司和生產廠家建立了長期穩固的合作關係，能夠提供進口物料跨境物流服務。我們的物流團隊經驗豐富、操作靈活，能夠根據客戶需求安排不同規模的運輸方案。無論是大貨車運輸至工地還是小型貨車直送少量貨物，我們都能提供最優質的運輸方案和最優惠的價格。此外，我們特設專業物流助手，負責全程監控貨物運輸狀況，保證貨物從出發地到收貨點的全程安全運輸，可跟上落貨，確保物料能夠快速、完好地送達客戶指定的目的地。",
                "lst": [
                    {
                        "title": "企業\/機構",
                        "desc": "享受最優惠的價格 · 最優質的建築材料 · 最完善的售後服務",
                        "desc_two": ""
                    },
                    {
                        "title": "合規企業",
                        "desc": "優質可靠的產品供應，快速供應鏈",
                        "desc_two": "減少產品運輸時間，符合進口的規範和標準，避免合規性問題"
                    },
                    {
                        "title": "GENGHIS 鐵木建材",
                        "desc": "自設貨倉，貨源穩定",
                        "desc_two": "一手貨源、智能比價、優質物流，極具競爭力的價格和專業的客戶服務"
                    },
                    {
                        "title": "國際知名品牌\/國內一線廠房",
                        "desc": "提供種類齊全、價格實惠、符合國際標準的高品質建築材料",
                        "desc_two": ""
                    }
                ]
            }
        },
     "address": [
            {
                "title": "中國香港",
                "desc": "電話咨詢：+852 6213 9561",
                "desc_two": "WHATSAPP: +852 6213 9561",
                "desc_three": "地址：新界屯門青發街18號",
                "desc_four": "營業時間：9:00 - 24:00"
            }
        ],
        "address_image": {
            "address_bg": "https:\/\/api.genghis.hk\/uploads\/images\/20240513\/202405130108275dddd3742.png",
            "logo": "https:\/\/api.genghis.hk\/uploads\/images\/test\/snef_log.png",
            "other_img_info": [
                {
                    "image": "https:\/\/api.genghis.hk\/uploads\/images\/20240511\/20240511233209828220045.png",
                    "desc": "email"
                },
                {
                    "image": "https:\/\/api.genghis.hk\/uploads\/images\/20240511\/20240511233209165af2864.png",
                    "desc": "phone"
                }
            ]
        },
        "other": {
            "title": "更多相關",
            "lst": [
                {
                    "title": "分批出貨",
                    "desc": "室內施工項目可存放物料的空間較小，大量存放將妨礙施工，甚至造成物料損壞。",
                    "type": "text",
                    "link_text": "了解更多",
                    "link": "\/self",
                    "color": "#002557",
                    "image": "",
                    "bg_color": "#F2F2F2"
                },
                {
                    "title": "",
                    "desc": "",
                    "type": "image",
                    "link_text": "",
                    "link": "",
                    "color": "",
                    "image": "https:\/\/api.genghis.hk\/uploads\/images\/test\/2131.png",
                    "bg_color": ""
                },
                {
                    "title": "貨源穩定",
                    "desc": "Genghis 作為國際知名品牌和國內一線廠房的戰略合作夥伴。",
                    "type": "text",
                    "link_text": "了解更多",
                    "link": "\/self",
                    "color": "#002557",
                    "image": "",
                    "bg_color": "#F2F2F2"
                },
                {
                    "title": "",
                    "desc": "",
                    "type": "image",
                    "link_text": "",
                    "link": "",
                    "color": "",
                    "image": "https:\/\/api.genghis.hk\/uploads\/images\/test\/21.png",
                    "bg_color": ""
                },
                {
                    "title": "",
                    "desc": "",
                    "type": "image",
                    "link_text": "",
                    "link": "",
                    "color": "",
                    "image": "https:\/\/api.genghis.hk\/uploads\/images\/test\/23325.png",
                    "bg_color": ""
                },
                {
                    "title": "預購方案",
                    "desc": "提供最優預購方案，客戶需先支付部分貨款預購，以享受預購價格優惠。",
                    "type": "text",
                    "link_text": "了解更多",
                    "link": "\/self",
                    "color": "#002557",
                    "image": "",
                    "bg_color": "#F2F2F2"
                },
                {
                    "title": "",
                    "desc": "",
                    "type": "image",
                    "link_text": "",
                    "link": "",
                    "color": "",
                    "image": "https:\/\/api.genghis.hk\/uploads\/images\/test\/shutterstock_1489986164.png",
                    "bg_color": ""
                }
            ]
        }
    };
  },
  mounted() {
   
  },
  created() {
    // getAbout().then((res) => {
    //   this.banner = res.data.banner;
    //   this.one = res.data.one;
    //   this.address = res.data.address;
    //   this.address_image = res.data.address_image;
    //   this.other = res.data.other;
    // });
  },
  methods: {
    onMouseleave() {
      var that = this;

      var other_list = that.$refs.other_list_box.getElementsByClassName("other-list");
      for (let index = 0; index < other_list.length; index++) {
        other_list[index].style.animationPlayState = "running";
      }
      // running
    },
    onMouseenter() {
      var that = this;

      var other_list = that.$refs.other_list_box.getElementsByClassName("other-list");
      for (let index = 0; index < other_list.length; index++) {
        other_list[index].style.animationPlayState = "paused";
      }
      // for (let index = 0; index < that.$refs.other_list.length; index++) {
      //   that.$refs.other_list[index].style.animationPlayState = "paused";
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-color {
  background-color: var(--color);
}
.other {
  background: #fff;
  padding-top: 40px;
  margin-bottom: 50px;
  .other-container {
    padding: 0 30px;
    .title {
      text-align: center;
      color: #002557;
      font-size: 24px;
      font-weight: bold;
    }
    .other-list {
      display: flex;
      margin-top: 20px;
      animation: rolling 10s linear infinite;
      width: 100%;
      .other-item {
        width: 145px;
        height: 145px;
        overflow: hidden;
        display: flex;
        .other-item-img {
          width: 145px;
          height: 145px;
        }
        .other-item-info {
          position: relative;
          padding: 15px 15px 0 15px;
          text-align: left;
          background: #f2f2f2;
          color: #000000;
          .other-item-info-title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .other-item-info-desc {
            font-size: 11px;
            line-height: 18px;
          }
          .other_btn {
            position: absolute;
            bottom: 10px;
            width: 100%;
            right: 20px;
            text-align: right;
            .other_btn_span {
              font-size: 12px;
              border: 1px solid #002557;
              color: #002557;
              background: #fff;
              cursor: pointer;
            }
          }
        }
        .other-item-info.blue {
          background: #002557;
          color: #fff;
        }
      }
    }
  }
}
.address {
  width: 100%;
  height: 350px;

  .address_info {
    padding: 30px;
    display: flex;

    .address_lfet {
      width: 540px;
      .address_mpa {
        margin-bottom: 20px;
      }
    }

    .address_right {
      flex: 1;
      padding-left: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 300px;
      .address_img {
        display: flex;
        align-items: end;
        .address_logo {
          width: 200px;
          height: 50px;
        }
        .address_img_msg {
          margin-left: 20px;
          .address_other_img {
            background: #fff;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin-left: 20px;
            border-radius: 50%;
          }
        }
      }
      .address_list_item {
        text-align: left;
        margin-bottom: 20px;

        .title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 15px;
          color: #fff;
        }

        .desc {
          font-size: 20px;
          margin-bottom: 15px;
          color: #fff;
        }
      }
    }
  }
}

.bg-wihte {
  height: 50px;
  width: 100%;
  background: #fff;
}

.tier-list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;

  .tier-list-item {
    margin-top: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left;

    .title {
      span {
        font-size: 20px;
        color: #fff;
        background: #002557;
        padding: 4px 20px;
        display: inline-block;
        margin-bottom: 10px;
      }
    }

    .desc {
      font-size: 16px;
      color: #474748;
      line-height: 20px;
    }
  }
}

.tier {
  margin-top: 30px;
  padding: 0 30px;

  .tier_title {
    color: #002557;
    border-bottom: 3px solid #002557;
    padding-bottom: 10px;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
  }

  .tier_desc {
    margin-top: 10px;
    font-size: 17px;
    line-height: 28px;
    text-align: left;
  }
}

.item_box_list {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;

  .item_box {
    width: 210px;
    height: 210px;
    display: flex;
    overflow: hidden;

    .item_info_box_desc {
      padding: 25px;
      color: #fff;
      font-size: 12px;
      line-height: 24px;
      text-align: left;
    }
  }
  .item_box_for:hover {
    background: #002557;
    transition: all 0.3s ease;

    .item_title {
      color: #fff;
    }
    .item_desc {
      color: #fff;
    }
  }
  .item_box_for {
    padding: 10px;
    background: #fff;
    flex-direction: column;

    .item_box_img {
      width: 190px;
      height: 90px;
    }

    .item_title {
      margin-top: 15px;
      font-size: 22px;
      font-weight: bold;
      color: #000000;
    }

    .item_desc {
      margin-top: 10px;
      font-size: 14px;
      color: #000000;
      text-align: left;
      line-height: 18px;
      padding: 0 20px;
    }
  }
}

.title_box {
  display: inline-block;
  border-bottom: 1px solid #1f1d1d;

  .desc {
    margin-top: 10px;
    color: #474748;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .container_item_title {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
  }

  .title {
    font-size: 42px;
    font-weight: bold;
  }

  .title_en {
    font-size: 26px;
    padding-left: 5px;
    font-weight: initial;
  }
}

.banner_container {
  padding-bottom: 50px;
}

.banner {
  display: flex;
  width: 100%;
}


.one-bg {
  background: #f6f6f6;
  padding: 50px 0;
  text-align: center;

  .title_box {
    .container_item_title {
      justify-content: center;
    }
  }
}

@keyframes rolling {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
.other-list_box {
  display: flex;

  flex-flow: nowrap;

  width: 100%;

  overflow: hidden;
}
</style>
