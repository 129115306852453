<template>
    <div>
        <appHeader></appHeader>
        <appNav></appNav>
        <div class="login_box">
            <div class="title">我的賬戶</div>
            <div class="from_item">
                <div class="from_item_title">
                    使用者電子郵箱<span>*</span>
                </div>
                <el-input placeholder="" v-model="email"></el-input>
            </div>
            <div class="from_item">
                <div class="from_item_title">
                    密碼<span>*</span>
                </div>
                <el-input :type="passwordState ? 'password' : 'text'" placeholder="" v-model="password">
                    <i class="el-icon-view el-input__icon" slot="suffix" @click="showPassword">
                    </i>
                </el-input>
            </div>
            <div class="from_item">
                <el-button class="login_btn" @click="login" :disabled="disres">登錄</el-button>
            </div>
            <div class="from_footer">
                <a class="aflex" href="/register">注冊</a>
                <a class="aflex" href="/forgot">忘記密碼?</a>
            </div>
        </div>
        <appFooter></appFooter>
    </div>
</template>
<script>
import appHeader from "@/components/header.vue"
import appFooter from "@/components/footer.vue"
import appNav from "@/components/nav.vue"
import { LoginApi } from "@/api/index.js";
import { validateEmailNumber } from "@/utils/email.js";


export default {
    components: {
        appHeader,
        appFooter,
        appNav
    },
    data() {
        return {
            email: "",
            password: "",
            passwordState: true,
            disres:false

        }
    },
    created(){
        var token = localStorage.getItem("token");
        console.log("%c Line:58 🌶 token", "background:#ffdd4d", token);
    if (token) {
        this.$router.push({ path: "/ourself" });
    } 
    },
    methods: {
        showPassword() {
            this.passwordState = !this.passwordState;
        },
        login(){
            if(this.email == ""){
                this.$message.error("請輸入登錄郵箱")
                return;
            }
            // var email_reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email);
            var email_reg = validateEmailNumber(this.email);
            if (!email_reg) {
                this.$message.error("请輸入正確郵箱");
                return;
                }
            if(this.password == ""){
                this.$message.error("請輸入登錄密碼")
                return;
            }
            
            this.disres = true;

            LoginApi({
                email:this.email,
                password:this.password,
            }).then(res=>{
                this.disres = false;
                if(res.code == 200){
                    this.$message.success("歡迎回來，"+res.data.nickname)
                    localStorage.setItem('token', res.data.token_unique);
                    localStorage.setItem('user',  JSON.stringify(res.data));
                    this.$router.push({path:'/product'});
                }else{
                    this.$message.error(res.msg)
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.from_footer {
    margin-top: 20px;
    display: flex;
    justify-content: end;

    .aflex {
        margin-left: 40px;
        color: #2E4F76;
    }
}

.login_btn {
    width: 100%;
    height: 45px;
    background: #2E4F76;
    border-radius: 17px;
    color: #fff;
}

.login_btn:hover,
.login_btn:active,
.login_btn:focus {
    background: #2E4F76;
    color: #fff;
}

.from_item {
    margin-bottom: 30px;
}

.from_item_title {
    margin-bottom: 20px;
    span {
        color: red;
    }
}

.title {
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    color: #2E4F76;
    line-height: 150px;
}

.login_box {
    padding: 50px 200px;
    width: 1176px;
    background: #FFFFFF;
    box-shadow: 0px 6px 8px 2px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    border: 1px solid #464646;
    margin: 100px auto;
}
</style>