<template>
  <div>
    <appHeader></appHeader>
    <appNav></appNav>
    <div class="login_box">
      <div class="title">修改電子郵箱</div>
      <div class="from_item">
        <div class="from_item_title">
          電子郵箱
          <span>*</span>
        </div>
        <el-input :placeholder="user.email" disabled v-model="user.email"></el-input>
      </div>
      <div class="from_item code_text">
        <div class="from_item_title">
          郵箱驗證碼
          <span>*</span>
        </div>
        <el-input placeholder="" v-model="user.email_code">
          <el-button slot="suffix"  class="get_code" @click="code" :disabled="disres">
            {{ text }}
          </el-button>
        </el-input>
      </div>
      <div class="from_item">
        <div class="from_item_title">
          新電子郵箱
          <span>*</span>
        </div>
        <el-input placeholder="" v-model="user.new_email"></el-input>
      </div>
      <div class="from_item code_text">
        <div class="from_item_title">
          新郵箱驗證碼
          <span>*</span>
        </div>
        <el-input placeholder="" v-model="user.new_email_code">
          <el-button slot="suffix"  class="get_code" @click="new_code" :disabled="disres">
            {{ text_new }}
          </el-button>
        </el-input>
      </div>      
    
      <div class="from_item">
        <el-button class="login_btn" @click="respassword" :disabled="disrest">修改電子郵箱</el-button>
      </div>
      <div class="from_footer">
        <a class="aflex" href="/login">登錄</a>
        <a class="aflex" href="/forgot">忘記密碼?</a>
      </div>
    </div>
    <appFooter></appFooter>
  </div>
</template>
<script>
import appHeader from "@/components/header.vue";
import appFooter from "@/components/footer.vue";
import appNav from "@/components/nav.vue";
import { getCodeApi, resemailApi,getUserInfoApi } from "@/api/index.js";
import { validateEmailNumber } from "@/utils/email.js";


export default {
  components: {
    appHeader,
    appFooter,
    appNav
  },
  data() {
    return {
      user: {
        email: "",
        email_code: "",
        new_email: "",
        new_email_code: ""
      },
      // passwordState: true,
      text: "獲取驗證碼",
      text_new: "獲取驗證碼",
      code_state: true,
      code_state_new: true,
      disres: false,
      disrest: false
    };
  },
  created(){
    var user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
    }
    
  },
  methods: {
    // showPassword() {
    //   this.passwordState = !this.passwordState;
    // },
    respassword() {
      if (this.user.email == "") {
        this.$message.error("郵箱不能爲空");
        return;
      }
      // var email_reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.user.email);
      var email_reg = validateEmailNumber(this.user.email);
      if (!email_reg) {
        this.$message.error("请輸入正確郵箱");
        return;
      }

      if (this.user.email_code == "") {
        this.$message.error("驗證碼不能爲空");
        return;
      }
      
      if (this.user.new_email == "") {
        this.$message.error("新電子郵箱不能爲空");
        return;
      }
      var email_reg_new = validateEmailNumber(this.user.new_email);
      if (!email_reg_new) {
        this.$message.error("请輸入正確的新電子郵箱");
        return;
      }
      if (this.user.new_email_code == "") {
        this.$message.error("新驗證碼不能爲空");
        return;
      }    
      this.disrest = true;
      resemailApi(this.user).then((res) => {
        this.disrest = false;
        if (res.code == 200) {
          this.$message.success(res.msg);
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          this.$router.push({ path: "/login" });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    code() {
      if (this.code_state) {
        if (this.user.email == "") {
          this.$message.error("郵箱不能爲空");
          return;
        }
        this.disres = true;
        getCodeApi({
          email: this.user.email
        }).then((res) => {
          this.disres = false;
          if (res.code == 200) {
            this.$message.success(res.msg);
            var num = 60;
            var t = setInterval(() => {
              this.text = num + "s";
              num--;
              if (num == 0) {
                this.code_state = true;
                this.text = "重新獲取";
                clearInterval(t);
              }
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    new_code() {
      if (this.code_state_new) {
        if (this.user.new_email == "") {
          this.$message.error("郵箱不能爲空");
          return;
        }
        this.disres = true;
        getCodeApi({
          email: this.user.new_email
        }).then((res) => {
          this.disres = false;
          if (res.code == 200) {
            this.$message.success(res.msg);
            var num = 60;
            var t = setInterval(() => {
              this.text = num + "s";
              num--;
              if (num == 0) {
                this.code_state_new = true;
                this.text = "重新獲取";
                clearInterval(t);
              }
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }    
  }
};
</script>
<style lang="scss" scoped>
.from_footer {
  margin-top: 20px;
  display: flex;
  justify-content: end;

  .aflex {
    margin-left: 40px;
    color: #2e4f76;
  }
}

.login_btn {
  width: 100%;
  height: 45px;
  background: #2e4f76;
  border-radius: 17px;
  color: #fff;
}

.login_btn:hover,
.login_btn:active,
.login_btn:focus {
  background: #2e4f76;
  color: #fff;
}

.get_code {
  //width: 100px;
  border-right: none;
  background: #2e4f76;
  color: #fff;
//   right: 0 !important;
    margin-right: -5px;
}

.get_code:hover,
.get_code:active,
.get_code:focus {
  background: #2e4f76;
  color: #fff;
}

.from_item {
  margin-bottom: 30px;
}

.from_item_title {
  margin-bottom: 20px;

  span {
    color: red;
  }
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  color: #2e4f76;
  line-height: 150px;
}

.login_box {
  padding: 50px 200px;
  width: 1176px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 2px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  border: 1px solid #464646;
  margin: 100px auto;
}
// .code_text {
//   .el-input__suffix {
//     right: 0 !important;
//   }
// }
</style>
<!-- <style >
  .el-input__suffix {
    right: 0 !important;
  }
</style> -->