<template>
  <div class="app-max-container container">
    <div class="nav_item">
      <template v-for="(item, index) in nav_config.nav">
        <a :href="item.link" class="nav_item_a" :class="{ select: isActive(item.link) }">{{ item.title }}</a>
        <template v-if="index != nav_config.nav.length - 1">
          <span class="footer_nav_line">|</span>
        </template>
      </template>
    </div>
    <div class="nav_query_container">
      <div class="nav_query">
        <el-input placeholder="請輸入材料關鍵字" v-model="keywords">
          <template slot="append" class="nav_query_btn">
            <i class="el-icon-search" @click="product"></i>
          </template>
        </el-input>
      </div>
    </div>
    <a @click="jumpCart" style="margin: 20px; display: flex"><img class="menu car" src="@/assets/icon/car.png" /></a>
  </div>
</template>
<script>
export default {
  computed: {
    // 计算属性来判断当前路径
    isActive() {
      return (path) => this.$route.path === path;
    }
  },
  data() {
    return {
      nav_config: {
        nav: [
        {
				"title": "首頁",
				"link": "/"
			}, {
				"title": "走進鐵木",
				"link": "/self"
			}, {
				"title": "購買材料",
				"link": "/product"
			}, {
				"title": "送貨方式",
				"link": "/send"
			}, {
				"title": "品質保障",
				"link": "/quality"
			}, {
				"title": " 聯繫我們",
				"link": "/about"
			}
        ]
      },
      keywords: ""
    };
  },
  created() {
    // const nav_config = JSON.parse(localStorage.getItem("nav_config"));
    // this.nav_config = nav_config;
  },
  methods: {
    product() {
      window.location.href = "/product?query="+ this.keywords
      // this.$router.push({ path: "/product?query=" + this.keywords });
    },
    jumpCart() {
      var token = localStorage.getItem("token");
      if (!token) {
        this.$router.push({ path: "/login" });
        return;
      } else {
        window.location.href = "/cart";
        // this.$router.push({ path: "/cart" });
      }
    }
  }
};
</script>
<style lang="scss">
.nav_query_container {
}
.nav_query {
  width: 300px;
  .el-input-group {
    .el-input-group__append {
      background: #000;
      cursor: pointer;
      .el-icon-search {
        font-size: 28px;
        color: #fff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.nav_item {
  flex: 1;
  display: flex;
  align-items: center;
}

.nav_item_a {
  text-align: center;
  padding: 0 22px;
  color: #000000;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2px;
  position: relative;
}
.nav_item_a:hover {
  color: #23436e;
}
.nav_item_a .select {
  color: #23436e;
}
.select::after {
  content: "";
  position: absolute;
  width: 90%;
  height: 3px;
  background: #23436e;
  bottom: -14px;
  left: 5%;
}
</style>
