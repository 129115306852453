<template>
  <div class="">
    <appHeader></appHeader>
    <appNav></appNav>
    <div class="app-max-container ">
      <img :src="banner" class="banner" />
      <div class="one">
        <div class="title_box">
          <div class="container_item_title">
            <p class="title">{{ one.title }}</p>
            <span class="title_en">{{ one.title_en }}</span>
          </div>
        </div>
        <div class="one_desc">{{ one.desc }}</div>
        <div class="one_item_list">
          <div class="one_item" v-for="item in one.lst">
            <div class="one_item_title">
              <img class="one_item_img" :src="item.image">
              {{ item.title }}
            </div>
            <p class="desc">{{ item.desc }}</p>

          </div>
        </div>

      </div>
    </div>
    <div class="two_bg">
      <div class="app-max-container ">
        <div class="two">
          <img :src="banner_two" class="banner" />
          <div class="two_container">
            <div class="two_container_item" v-for="item in two">
              <p class="two_container_title">{{ item.title }}</p>
              <div class="two_container_list" v-for="info in item.lst">
                <p class="two_container_list_item_title">{{ info.title }}</p>
                <p class="two_container_list_item_desc" v-html="info.desc"></p>
              </div>
            </div>
          </div>
          <!-- <div class="btn_box">
            <a class="btn" href="/">了解更多</a>
          </div> -->
        </div>
      </div>
    </div>

    <div class="three">
      <div class="app-max-container ">
        <div class="title_box">
          <div class="container_item_title">
            <p class="title">{{ three.title }}</p>
            <span class="title_en">{{ three.title_en }}</span>
          </div>
        </div>
        <div class="three_body">
          <div class="three_lfet">
            <div class="three_lfet_list">
              <div class="three_lfet_list_item" v-for="item in three.lst">
                <span class="three_lfet_list_item_title">{{ item.title }}</span>
                <p class="three_lfet_list_item_desc">{{ item.desc }}</p>

              </div>
            </div>
          </div>
          <div class="three_right">
            <div class="znawei"></div>
            <div class="three_right_img_box">
              <img class="three_right_img" :src="item" v-for="item in three.image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="five">
      <img :src="banner_three" class="banner" />
    </div>
    <appLink></appLink>

    <appFooter></appFooter>
  </div>
</template>

<script>
import { getPz } from "@/api/index.js";
import appHeader from "@/components/header.vue"
import appFooter from "@/components/footer.vue"
import appNav from "@/components/nav.vue"
import appLink from "@/components/link.vue"

export default {
  components: {
    appHeader,
    appFooter,
    appNav,
    appLink

  },
  data() {
    return {
      "banner": "https://api.genghis.hk/uploads/images/test/pzb1.png",
      "banner_two": "https://api.genghis.hk/uploads/images/test/pzb2.png",
      "banner_three": "https://api.genghis.hk/uploads/images/test/pz3.png",
      "one": {
        "title": "四大優勢",
        "title_en": "ABOUT GENGHIS",
        "desc": "品質始終是 Genghis 的首要關注點。我們與國際領先的建材供應商和國內一線廠房合作，擁有廣泛而可靠的供應鏈，確保我們所提供的產品符合最高標準 滿足不同建築項目的需求。Genghis 品質控制團隊對每一批貨物進行嚴格的檢驗，使每一款產品的品質和性能都符合客戶的期望",
        "lst": [
          {
            "image": "https://api.genghis.hk/uploads/images/test/pzbi.png",
            "desc": "Genghis 擁有第一手貨源，種類齊全，客戶可以輕鬆通過官網/來電直接訂購，享受市面最佳價格。",
            "title": "價格合理",
            "link_text": "了解更多",
            "link": ""
          },
          {
            "image": "https://api.genghis.hk/uploads/images/test/pzbox.png",
            "desc": "Genghis 擁有第一手貨源，種類齊全，客戶可以輕鬆通過官網/來電直接訂購，享受市面最佳價格。",
            "title": "貨源穩定",
            "link_text": "了解更多",
            "link": ""
          },
          {
            "image": "https://api.genghis.hk/uploads/images/test/pzke.png",
            "desc": "我們提供全方位的客戶服務，包括專業建議、產品選擇、送貨服務以及售後支援，竭盡所能給予客戶及時的幫助。",
            "title": "客戶服務",
            "link_text": "了解更多",
            "link": ""
          },
          {
            "image": "https://api.genghis.hk/uploads/images/test/pzcar.png",
            "desc": "Genghis 致力於提供高效率和可靠性的物流配送服務，以保證貨物能夠按時、完好地送達目的地，貨款$2000起即可免運送費用（香港市區）。",
            "title": "物流配送",
            "link_text": "了解更多",
            "link": ""
          }
        ]
      },
      "two": [
        {
          "title": "大型工程",
          "lst": [
            {
              "title": "智能比价",
              "desc": "在大型施工工程中，採購建築材料的成本是一個重要的考量因素。Genghis 的智能比價系統，為施工方提供了便捷的採購選項，不僅可以幫助施工方<span class=\"mx_red\">足不出戶貨比三家</span>，選擇最適合的建材品牌"
            },
            {
              "title": "分批出貨",
              "desc": "物流團隊按照施工計劃進行分批出貨，物料將根據施工進度和項目需求定時送達施工現場，因此施工方<span class=\"mx_red\">無需額外投入大量資金進行大規模倉儲。</span>"
            },
            {
              "title": "分期付款",
              "desc": "對於大型施工工程，大量的建材採購往往需要支付高額貨款，這對施工方的資金流動性是一個挑戰。Genghis 提供<span class=\"mx_red\">「智能預購，分期付款」</span>方案，通過對施工計劃的智能運算，得出最佳分期訂購方案，施工方只需支付支付部分貨款預購，便可以享受預購優惠價，<span class=\"mx_red\">節省15%-20%的成本</span>，並根據項目進度和資金安排來進行分期付款，減輕了一次性支付所帶來的壓力。"
            }
          ]
        },
        {
          "title": "小型工程",
          "lst": [
            {
              "title": "分批出貨",
              "desc": "室內施工項目可存放物料的<span class=\"mx_red\">空間較小</span>，大量存放將妨礙施工，甚至造成物料損壞。"
            },
            {
              "title": "分批出貨",
              "desc": "小型裝修項目施工現場的<span class=\"mx_red\">人手往往較少</span>，，如需調動大部分人力、花費大量時間用於物料運送，可能會嚴重影響施工進度。"
            },
            {
              "title": "分期付款",
              "desc": "對於<span class=\"mx_red\">小型施工工程</span>，如家庭裝修或局部維修項目，材料的需求量往往相對較少。"
            },
            {
              "title": "$2000即免運費",
              "desc": "當材料需求量小、總貨款較小時，<span class=\"mx_red\">運輸費用也會成為小型</span>項目的一個額外負擔。"
            }
          ]
        }
      ],
      "three": {
        "title": "綠色建材",
        "title_en": "ABOUT GENGHIS",
        "image": [
          "https://api.genghis.hk/uploads/images/test/pz1.png",
          "https://api.genghis.hk/uploads/images/test/pz2.png"
        ],
        "lst": [
          {
            "desc": "綠色建材通常由可再生或回收材料製成，並且在生產過程中產生的排放較少，從而減少對自然資源的消耗，減少能源使用和碳足跡，從而降低對環境的不良影響。",
            "title": "環境保護"
          },
          {
            "desc": "綠色建材通常不含有害的揮發性有機化合物（VOC），如甲醛等有害物質，危害人體健康。使用綠色建材可以改善室內空氣質量，降低室內空氣中有害物質的濃度，減少室內污染，提高居住和工作環境的舒適度。",
            "title": "健康舒適"
          },
          {
            "desc": "綠色建材具有良好的隔熱和保溫性能，可以幫助降低能源消耗，減少空調和供暖的使用，降低能源成本。此外，一些綠色建材還可以利用太陽能或其他可再生能源進行供能，進一步提高能源效益。",
            "title": "能源效益"
          },
          {
            "desc": "不少綠色建材具有較長的使用壽命，並且在使用過程中需要較少的維護和更換，可以降低長期維護成本，減少資源浪費和環境負擔，並且對建築的可持續性和長期價值有著積極的提高。",
            "title": "持久耐用"
          },
          {
            "desc": "使用綠色建材可以促進綠色產業的發展，創造就業機會，並推動技術創新和可持續發展，有助於推動綠色經濟的發展，促進社會的可持續發展。",
            "title": "社會影響"
          }
        ]
      }

    };
  },
  created() {
    // getPz().then(res => {
    //   console.log(res)
    //   this.banner = res.data.banner;
    //   this.banner_two = res.data.banner_two;
    //   this.banner_three = res.data.banner_three;
    //   this.one = res.data.one;
    //   this.two = res.data.two;
    //   this.three = res.data.three;

    // })
  },
  methods: {

  }
};
</script>
<style>
.mx_red {
  color: #B90000 !important;
}
</style>
<style lang="scss" scoped>
.five {
  margin-top: 50px;
  margin-bottom: 100px;
}

.three {
  padding-top: 50px;

  .title_box {
    margin-bottom: 50px;
  }

  .three_body {
    display: flex;

    .three_lfet {
      flex: 1;

      .three_lfet_list_item {
        margin-bottom: 5px;

        .three_lfet_list_item_title {
          background: #002557;
          color: #FFFFFF;
          display: inline-block;
          font-size: 22px;
          margin-top: 13px;
          line-height: 30px;
          padding: 3px 10px;
          letter-spacing: 1.5px;
          margin-bottom: 10px;
        }

        .three_lfet_list_item_desc {
          font-size: 16px;
          color: #474748;
          line-height: 18px;
          padding-left: 10px;
        }
      }

    }

    .three_right {
      padding-left: 20px;
      width: 450px;
      display: flex;
      flex-direction: column;

      .znawei {
        height: 60px;
      }

      .three_right_img_box {
        flex-direction: column;
        flex: 1;
        display: flex;
        justify-content: space-between;
      }

      .three_right_img {
        width: 100%;
      }
    }
  }
}

.two_bg {
  background: #F6F6F6;
}

.two {
  margin-top: 50px;

  .btn_box {
    text-align: center;
    height: 80px;

    .btn {
      padding: 0px 5px;
      font-size: 20px;
      color: #002557;
      border: 3px solid #002557;
      letter-spacing: 4px;
      font-weight: bold;
    }
  }

  .two_container {
    padding: 0 20px;
    display: flex;
    margin-top: 20px;

    .two_container_item {
      flex: 1;
      padding: 20px;

      .two_container_title {
        background: #002557;
        color: #FFFFFF;
        display: inline-block;
        font-size: 24px;
        margin-top: 13px;
        line-height: 30px;
        padding: 3px 10px;
        letter-spacing: 1.5px;
        margin-bottom: 20px;
      }

      .two_container_list {
        margin-bottom: 20px;
      }

      .two_container_list_item_title {
        font-size: 14px;
        font-weight: bold;
        color: #002557;
      }

      .two_container_list_item_desc {
        margin-top: 10px;
        font-size: 13px;
        color: #474748;
        line-height: 18px;

      }

    }
  }
}

.banner {
  display: flex;
  width: 100%;
}

.one {
  padding: 0 20px;
  margin-top: 50px;
  text-align: center;

  .one_desc {
    margin-top: 10px;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.2px;
  }

  .one_item_list {

    margin-top: 20px;
    display: flex;
    border: none;
    width: 100%;
    justify-content: space-between;

    .one_item {
      width: 280px;
      height: 180px;
      display: flex;
      flex-direction: column;
      background: #F2F2F2;
      padding: 15px 30px;
      border-radius: 10px;

      .one_item_title {
        display: flex;
        font-size: 20px;
        font-weight: bold;
        align-items: center;
        color: #000;

        .one_item_img {
          width: 40px;
          height: 40px;
          margin-right: 15px;
        }


      }

      .desc {
        margin-top: 10px;
        flex: 1;
        text-align: left;
        line-height: 20px;
        letter-spacing: 1px;
        font-size: 13px;
        color: #474748;

      }

      .btn_box {
        display: flex;
        justify-content: flex-end;
        align-items: start;
        height: 35px;

        .btn {
          padding: 5px 20px;
          font-size: 12px;
          color: #002557;
          border: 1px solid #002557;
          letter-spacing: 4px;
          font-weight: bold;
        }
      }

    }

    .one_item:nth-child(2n+1) {
      background: #fff;
      border: 1px dashed #002557;
    }

  }
}

.title_box {
  display: inline-block;
  border-bottom: 1px solid #1f1d1d;

  .desc {
    margin-top: 10px;
    color: #474748;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .container_item_title {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
  }

  .title {
    font-size: 42px;
    font-weight: bold;
  }

  .title_en {
    font-size: 26px;
    padding-left: 5px;
    font-weight: initial;
  }


}
</style>