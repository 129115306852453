<template>
    <div class="">
        <appHeader></appHeader>
        <appNav></appNav>
        <div class="app-max-container">
            <img class="banner_img" :src="one_container.banner_imag" v-if="one_container.banner_imag">
            <div class="one_container">
                <div class="one_container_item" v-for="item in one_container.lst">
                    <img class="one_container_item_img" :src="item.image">
                    <div class="one_container_item_info">
                        <p class="title">{{ item.title }}</p>
                        <span class="line"></span>
                        <p class="desc">{{ item.desc }}</p>
                        <p class="desc">{{ item.desc_two }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="two_container_box">
            <div class="app-max-container two_container">
                <div class="two_container_item">
                    <div class="two_container_item_title_box title_box">
                        <div class="two_container_item_title container_item_title">
                            <p class="title">{{ two_container.title }}</p>
                            <span class="title_en">{{ two_container.title_en }}</span>
                        </div>
                    </div>
                    <p class="desc">{{ two_container.desc_one }}</p>
                    <p class="desc desc_two">{{ two_container.desc_two }}</p>
                </div>
                <img :src="two_container.image" class="two_container_image" />
            </div>
        </div>
        <div class="app-max-container">
            <div class="three_container">
                <div class="three_container_left">
                    <img :src="three_container.image">
                    <img class="img_two" :src="three_container.image_two">
                </div>
                <div class="three_container_right">
                    <div class="three_container_right_info">
                        <div class="title_box">
                            <div class="container_item_title">
                                <p class="title">{{ three_container.title }}</p>
                                <span class="title_en">{{ three_container.title_en }}</span>
                            </div>
                        </div>
                        <div class="desc">{{ three_container.desc }}</div>
                        <a class="btn" v-if="three_container.link_text" :href="three_container.link">{{
                            three_container.link_text }}</a>
                    </div>
                    <div class="three_container_right_lst">
                        <div class="three_container_right_item" v-for="item in three_container.lst">
                            <p class="title">{{ item.title }}</p>
                            <p class="desc">{{ item.desc }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="four_container_box">
            <div class="app-max-container ">
                <div class="four_container">
                    <div class="title">
                        <span class="line"></span>
                        <p>{{ four_container.title }} </p>
                        <span class="line"></span>
                    </div>
                    <p class="desc">{{ four_container.desc }}</p>

                    <div class="four_container_carousel">
                        <el-carousel :interval="5000" height="200px" arrow="never" indicator-position="none"
                            class="banner" ref="cardShow">
                            <el-carousel-item v-for="(item, index) in four_container.lst" :key="index">
                                <div class="carousel_item_list">
                                    <template v-for="info in item">
                                        <template v-if="info.link">
                                            <a class="carousel_item" :href="info.link">
                                                <p class="carousel_title">{{ info.title }}</p>
                                                <p class="carousel_desc">{{ info.desc }}</p>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <div class="carousel_item">
                                                <p class="carousel_title">{{ info.title }}</p>
                                                <p class="carousel_desc">{{ info.desc }}</p>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                        <img src="@/assets/icon/left.png" class="left" @click="pre" />
                        <img src="@/assets/icon/right.png" class="right" @click="nex" />
                    </div>

                </div>

            </div>
            <img class="footer_image" :src="four_container.footer_image">
        </div>
        <div style="height: 50px;"></div>
        <appLink></appLink>

        <appFooter></appFooter>
    </div>
</template>

<script>
import { getSelf } from "@/api/index.js";
import appHeader from "@/components/header.vue"
import appFooter from "@/components/footer.vue"
import appNav from "@/components/nav.vue"
import appLink from "@/components/link.vue"

export default {
    components: {
        appHeader,
        appFooter,
        appNav,
        appLink
    },
    data() {
        return {
            one_container: {
                "banner_imag": "https://api.genghis.hk/uploads/images/test/self/shutterstock_1366579895.png",
                "lst": [
                    {
                        "title": "價格合理",
                        "image": "https://api.genghis.hk/uploads/images/test/self/bill.png",
                        "desc": "種類齊全",
                        "desc_two": "享受市面最佳價格"
                    },
                    {
                        "title": "貨源穩定",
                        "image": "https://api.genghis.hk/uploads/images/test/self/box.png",
                        "desc": "知名品牌",
                        "desc_two": "供應鏈遍布全國"
                    },
                    {
                        "title": "客戶服務",
                        "image": "https://api.genghis.hk/uploads/images/test/self/msg.png",
                        "desc": "私人訂料秘書",
                        "desc_two": "實現預期的效果"
                    },
                    {
                        "title": "物流配送",
                        "image": "https://api.genghis.hk/uploads/images/test/self/car.png",
                        "desc": "免運送費",
                        "desc_two": "提供高效率物流配送"
                    }
                ]
            },
            two_container: {
                "image": "https://api.genghis.hk/uploads/images/test/self/shutterstock_1327120316.png",
                "title": "鐵木建材",
                "title_en": "ABOUT GENGHIS",
                "desc_one": "Genghis 香港鐵木建材有限公司自成立以來，一直為廣大客戶提供安全、可靠、優質的建築材料及施工用品，協助建築公司、房地產開發商、政府部門、機構組織等實現各種建築計畫，為各類建築工程和施工項目供應一系列高品質的材料及設備，以確保建築項目的順利完成、安全可靠和長期耐用性。",
                "desc_two": "我們的產品範圍包括但不限於砂石、木材、鋼材、水泥、瀝青、管道、線材、五金、安全裝備等，以及室內裝修所需的各種建材，例如天花地線、冷氣機、洗手間/浴室/廚房磁磚等等，以嚴格的品質控制程序確保所提供的產品均符合國際標準，且來自國際知名品牌，以確保客戶獲得最高品質的建築材料。"
            },
            three_container: {
                "image": "https://api.genghis.hk/uploads/images/test/self/shutterstock_1366579898.png",
                "image_two": "https://api.genghis.hk/uploads/images/test/self/shutterstock_1924611887.png",
                "title": "智能線上訂購模式",
                "title_en": "INTELLIGENT PRE-ORDER SYSTEM",
                "desc": "我們為客戶提供便捷的訂購流程，使其能夠輕鬆購得所需建築材料。客戶可以透過「線上詢價」的方式，提出材料需求及目標價格後，直接享受我們的優惠批發價。而為了讓客戶更好地控制成本並按計劃進行施工，我們也支持也可按照施工計畫採取「智能預購」模式，以分批付款分批出貨進行彈性交易，無須擔心在施工建築過程中出現材料短缺問題，我們將保證按時提供高質量的建築材料。",
                "link": "",
                "link_text": "",
                "lst": [
                    {
                        "title": "了解需求",
                        "desc": "除了常規的詢價單，客戶還需提供各項物料的大致送達時間，由此我們可以通過智能比價，計算出最佳交易時間，避免價格上浮，為客戶提供更加優惠的價格；更好地規劃物料的備貨和運輸安排，盡可能避免因運輸進度延誤而導致的不便和損失，確保能夠按時、準確地將物料送達到客戶所需的目的地。"
                    },
                    {
                        "title": "預購方案",
                        "desc": "經過智能運算合理安排物料的訂購/運送時間，提供最優預購方案，客戶需先支付部分貨款預購，以享受預購價格優惠。智能預購方案不僅能夠幫助客戶節省成本，"
                    },
                    {
                        "title": "分期訂購",
                        "desc": "Genghis 依照預購方案按時提醒客戶確認每期訂購的物料項目和數量，完成支付流程，合理安排運送時間，避免施工進度受到影響。我們的團隊將與客戶保持密切的溝通和協調，及時提供各期訂購物料的詳細信息和相關建議，確保客戶能夠清楚了解每期訂購物料的具體情況和計劃安排，並能夠根據自身施工進度做出及時的調整和安排，避免施工進度受到影響，確保項目能夠按時進行和完成。的備貨和運輸安排，盡可能避免因運輸進度延誤而導致的不便和損失，確保能夠按時、準確地將物料送達到客戶所需的目的地。"
                    },
                    {
                        "title": "分批出貨",
                        "desc": "依時把客戶訂購的貨物送達指定地點，降低客戶倉儲成本的同時確保施工順利。我們將根據客戶提供的送達時間和地點信息，合理安排物流運輸的順序和時間，並及時提供物料運送的最新進度和相關信息，使客戶能夠隨時了解貨物的運送狀況和預計的送達時間，根據施工進度和倉儲需求做出及時的調整和安排。的備貨和運輸安排，盡可能避免因運輸進度延誤而導致的不便和損失，確保能夠按時、準確地將物料送達到客戶所需的目的地。"
                    }
                ]

            },
            four_container: {
                "footer_image": "https://api.genghis.hk/uploads/images/test/self/footer.png",
                "title": "材料及施工用品",
                "desc": "我們鐵木建材公司承諾為客戶提供卓越的建築材料和服務。無論您來自建築公司、房地產開發商、政府部門還是機構組織，我們都樂意成為您的合作夥伴，協助您實現建築項目的成功。我們的產品範圍廣泛，品質優良，價格合理，並提供可持續發展的建築解決方案，以滿足當今及未來的需求。請聯繫我們，讓我們為您的項目提供最佳的服務和支持。",
                "lst": [
                    [
                        {
                            "title": "喉管",
                            "desc": "喉管是一種重要的建築材料，用於配管系統的建設和設計，可由金屬材料製成，如鍍鋅鋼、不銹鋼或銅等......",
                            "link": ""
                        },
                        {
                            "title": "板材",
                            "desc": "建築板材可用於建築結構和室內裝修，通常由木材、鋼材、水泥、玻璃纖維、塑料或複合材料等製成......",
                            "link": ""
                        },
                        {
                            "title": "鋼鐵金屬",
                            "desc": "在建築工程中，鋼鐵用於構建建築物的骨架結構和支撐系統，能夠確保建築物的結構穩固性、耐久性和安全性......",
                            "link": ""
                        },
                        {
                            "title": "安全用品",
                            "desc": "建築業工作者將自己暴露在極端的危害性防護 ，無論是通過日常的實踐任務，設備操作，還是專案管理......",
                            "link": ""
                        },
                        {
                            "title": "沙泥",
                            "desc": "泥沙可以用於建築施工和土木工程中的不同用途，主要包括河沙、海沙、石灰石粉、石粉、黏土...",
                            "link": ""
                        }
                    ],
                    [
                        {
                            "title": "喉管",
                            "desc": "喉管是一種重要的建築材料，用於配管系統的建設和設計，可由金屬材料製成，如鍍鋅鋼、不銹鋼或銅等......",
                            "link": ""
                        },
                        {
                            "title": "板材",
                            "desc": "建築板材可用於建築結構和室內裝修，通常由木材、鋼材、水泥、玻璃纖維、塑料或複合材料等製成......",
                            "link": ""
                        },
                        {
                            "title": "鋼鐵金屬",
                            "desc": "在建築工程中，鋼鐵用於構建建築物的骨架結構和支撐系統，能夠確保建築物的結構穩固性、耐久性和安全性......",
                            "link": ""
                        },
                        {
                            "title": "安全用品",
                            "desc": "建築業工作者將自己暴露在極端的危害性防護 ，無論是通過日常的實踐任務，設備操作，還是專案管理......",
                            "link": ""
                        },
                        {
                            "title": "沙泥",
                            "desc": "泥沙可以用於建築施工和土木工程中的不同用途，主要包括河沙、海沙、石灰石粉、石粉、黏土...",
                            "link": ""
                        }
                    ]
                ]
            }
        };
    },
    created() {
        // getSelf().then(res => {
        //     this.one_container = res.data.one_container;
        //     this.two_container = res.data.two_container;
        //     this.three_container = res.data.three_container;
        //     this.four_container = res.data.four_container;
        //     console.log(this.four_container)
        // })
    },
    methods: {
        pre() {
            console.log("11111")
            console.log(this.$refs)
            this.$refs.cardShow.prev()
        },
        nex() {
            console.log("2222")
            console.log(this.$refs)
            this.$refs.cardShow.next()
        }
    }
};
</script>

<style lang="scss" scoped>
.footer_image {
    width: 100%;
}

.four_container_carousel {
    margin-top: 30px;
    position: relative;

    .left,
    .right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .left {
        left: -50px;
    }

    .right {
        right: -50px;
    }
}

.carousel_item_list {
    display: flex;
    justify-content: space-between;
    height: 200px;
    align-items: center;

    .carousel_item {
        border-radius: 45%;
        background: #FFFFFF;
        border-radius: 50px;
        width: 180px;
        height: 160px;
        border: 1px dashed #002557;
        position: relative;
        padding: 20px;
        padding-top: 40px;

        .carousel_title {
            position: absolute;
            background: #002557;
            color: #FFFFFF;
            display: inline-block;
            font-size: 16px;
            width: 110px;
            height: 30px;
            line-height: 30px;
            border-radius: 20px;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            top: -15px;
            font-weight: bold;
        }

        .carousel_desc {
            font-size: 12px;
            color: #000000;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            /* 定义显示的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 16px;
            padding-top: 5px;
        }
    }
}

.four_container_box {
    background: #F2F2F2;
    margin-top: 40px;
    width: 100%;

    .four_container {
        padding: 40px 0;
        width: 80%;
        margin-left: 10%;

        .title {
            color: #002557;
            font-size: 30px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;

            .line {
                flex: 1;
                height: 1px;
                background-color: #002557;
            }

            p {
                padding: 0 20px;
            }
        }

        .desc {
            margin-top: 10px;
            color: #474748;
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 20px;
            margin-top: 40px;
            text-align: center;
        }
    }
}

.three_container {
    display: flex;
    margin-top: 130px;

    .three_container_left {
        width: 400px;
        display: flex;
        flex-direction: column;

        .img_two {
            margin-top: 5px;
        }

        img {
            width: 100%;
            max-height: 239px;
        }

    }

    .three_container_right {
        flex: 1;

        .three_container_right_info {
            padding-left: 50px;
            padding-top: 25px;

            .title {
                font-size: 38px;
            }

            .title_en {
                font-size: 26px;
            }

            .desc {
                margin-top: 10px;
                color: #474748;
                font-size: 16px;
                line-height: 28px;
                margin-bottom: 20px;
            }

            .btn {
                background: #002557;
                color: #FFFFFF;
                display: inline-block;
                font-size: 16px;
                margin-top: 13px;
                line-height: 30px;
                padding: 5px 15px;
                letter-spacing: 1.5px;
            }
        }

        .three_container_right_lst {
            margin-top: 40px;
            display: flex;
            justify-content: space-around;

            .three_container_right_item {
                width: 180px;
                height: 210px;
                padding: 20px;
                color: #474748;
                background-color: #F2F2F2;
                cursor: pointer;

                .title {
                    margin-bottom: 10px;
                    font-size: 18px;
                    font-weight: bold;
                }

                .desc {
                    font-size: 12px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 7;
                    /* 定义显示的行数 */
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 18px;
                }
            }

            .three_container_right_item:hover {
                background: #002557;
                color: #fff;
                transition: all 0.3s ease;
            }
        }


    }
}

.two_container {
    position: relative;

    .two_container_image {
        position: absolute;
        right: 0;
        top: -45px;
        width: 590px;
    }
}

.title_box {
    display: inline-block;
    border-bottom: 1px solid #000000;

    .container_item_title {
        display: flex;
        align-items: flex-end;
        padding-bottom: 10px;
    }

    .title {
        font-size: 42px;
        font-weight: bold;
    }

    .title_en {
        font-size: 26px;
        padding-left: 5px;
        font-weight: initial;
    }

    .desc {
        margin-top: 10px;
        color: #474748;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
    }
}

.two_container_box {
    background: #F2F2F2;
    margin-top: 95px;

    .two_container_item {
        padding: 30px 0;
        width: 690px;

        .desc {
            margin-top: 20px;
            color: #474748;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.5px;
            margin-bottom: 20px;
        }


        .desc_two {
            margin-bottom: 0;
        }

    }
}

.banner_img {
    width: 100%;
    display: flex;
}

.one_container {
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;

    .one_container_item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 50px;

        .one_container_item_img {
            width: 65px;
            margin-right: 20px;
        }

        .one_container_item_info {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            color: #fff;

            .title {
                font-weight: bold;
                font-size: 16px;
            }

            .desc {
                font-size: 12px;
                line-height: 20px;
            }

            .line {
                width: 55px;
                margin: 8px 0 5px 0;
                display: inline-block;
                height: 1px;
                background: #FFFFFF;
            }
        }


    }

    .one_container_item:nth-child(2n+1) {
        background: #002557;
    }

    .one_container_item:nth-child(2n) {
        background: #F2F2F2;

        .one_container_item_info {
            color: #585858;

            .title {
                color: #002557;
            }

            .line {
                background: #5F5F5F;
            }
        }
    }
}
</style>