<template>
  <div class="contact" v-if="visible" @click="visible = false">
    <div class="four contact_box"  @click.stop="">
      <div class="four_container">
        <div class="four_container_right">
          <div class="send_email">
            <div class="four_container_right_hedaer">
              <img :src="four.send_logo" />
              <span>{{ four.send_title }}</span>
            </div>
            <el-input class="input-send" v-model="user.username" placeholder="如何稱呼您"></el-input>
            <el-input class="input-send" v-model="user.email" placeholder="電子郵箱"></el-input>
            <el-input class="input-send" v-model="user.phone" placeholder="聯係電話 例如 +852622139561"></el-input>
            <el-input class="input-send" v-model="user.reason" placeholder="您的需求"></el-input>
            <el-button round class="send_btn" @click.stop="submit">提交</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSend, addIndexUserLinkedtApi } from "@/api/index.js";
import { validatePhoneNumber } from "@/utils/phone.js";
import { validateEmailNumber } from "@/utils/email.js";

export default {
  data() {
    return {
      visible: false,
      user: {
        username: "",
        email: "",
        phone: "",
        reason: ""
      },
      send_state: true,
      four: {
        send_bg: "http://api.genghis.hk/uploads/images/test/send_bg.png",
        send_logo: "http://api.genghis.hk/uploads/images/20240511/20240511222150ae5907264.png",
        send_title: "聯繫我們"
      }
    };
  },
  methods: {
    show() {
      var that = this;
      that.visible = true;
    },
    submit() {
      var that = this;
      if (this.send_state) {
        if (this.user.username == "") {
          this.$message.error("如何稱呼您");
          return;
        }
        if (this.user.email == "" && this.user.phone) {
          this.$message.error("請輸入電子郵箱 或 聯係電話");
          return;
        }

      // var email_reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.user.email);
      // var mobile_reg = /^(\+?\d{1,4})?\s?-?\s?\(?(\d{3})\)?\s?-?\s?(\d{3})\s?-?\s?(\d{4})$/.test(this.user.phone);
      var email_reg = validateEmailNumber(this.user.email);
      var mobile_reg = validatePhoneNumber(this.user.phone);

      if (!email_reg) {
        that.$message.error("请輸入正確郵箱");
        return;
      }
      if (!mobile_reg) {
        that.$message.error("请輸入正確手機號碼");
        return;
      }
      
      if (this.user.reason == "") {
          this.$message.error("請輸入您的需求");
          return;
        }
        this.send_state = false;
        // registerApi({
        //   xxxxxx
        // }).then(res=>{
        //   // 状态200 
        //    if (res.code == 200) {
        //      //需求的成功 
        //    }
        //   console.log(res)
        // },err=>{
        //   // 1 网络的问题 
        //   // 异常  
        // })

        addIndexUserLinkedtApi(that.user).then((res) => {
          this.send_state = true;
          if (res.code == 200) {
            this.user = {
              username: "",
              email: "",
              phone: "",
              reason: ""
            };
            that.visible = false;
            that.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.contact_box{
  background: #fff;
  border-radius: 30px;
}
.contact {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  /* 为防止页面中有其他定位元素的z-index较大，可以将这里的z-index设置的得大一些 */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.four_container_right_hedaer {
  display: flex;
  align-items: end;

  span {
    font-size: 30px;
    color: #002557;
    font-weight: bold;
    padding-left: 30px;
  }
}

.four {
  //   background: #cfdae8;
  position: relative;
  background-size: 100%;
  padding: 30px;
  background-repeat: no-repeat;
  background-size:cover;
  //   margin-top: 50px;
  .sned_bg {
    position: absolute;
    // height: 100%;
    // width: 50%;
    // top: 0;
    // right: 0;
    // z-index: 0;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .four_container {
    padding: 30px;
    display: flex;

    .four_container_left,
    .four_container_right {
      //   flex: 1;
      //   position: relative;
      z-index: 10;
    }

    // .four_container_left {
    //   padding-right: 40px;

    //   .desc {
    //     margin-top: 20px;
    //     line-height: 24px;
    //     letter-spacing: 0.4px;
    //     font-size: 14px;
    //   }
    // }
  }
}
.send_btn {
  margin-top: 50px;
  width: 70%;
  margin-left: 15%;
  background: #002557;
  color: #fff;
  border: none;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 10px;
}

.input-send {
  margin-top: 30px;
}
</style>
