<template>
  <div>
    <div class="footer">
      <div class="app-max-container">
        <div class="footer_taps">
          <template v-for="(item, index) in footer_config.taps">
            <a href="/about"class="footer_taps_item"  v-if="index == 0">
              <img class="footer_taps_img" :src="item.img" />
              <p class="footer_taps_title">{{ item.title }}</p>
              <p class="footer_taps_line"></p>
              <p class="footer_taps_desc">{{ item.desc }}</p>
              <p class="footer_taps_desc_two">{{ item.desc_two }}</p>
            </a>
            <div class="footer_taps_item"  v-if="index == 1" @click="showEmalil">
              <img class="footer_taps_img" :src="item.img" />
              <p class="footer_taps_title">{{ item.title }}</p>
              <p class="footer_taps_line"></p>
              <p class="footer_taps_desc">{{ item.desc }}</p>
              <p class="footer_taps_desc_two">{{ item.desc_two }}</p>
            </div>
            <a target="_blank" :href="'https://api.whatsapp.com/send/?phone='+header_config.whatapp+'&text&type=phone_number&app_absent=0'" class="footer_taps_item"  v-if="index == 2">
              <img class="footer_taps_img" :src="item.img" />
              <p class="footer_taps_title">{{ item.title }}</p>
              <p class="footer_taps_line"></p>
              <p class="footer_taps_desc">{{ item.desc }}</p>
              <p class="footer_taps_desc_two">{{ item.desc_two }}</p>
            </a>
          </template>
        </div>
        <div class="cooperation_images">
          <template v-for="(item, index) in footer_config.cooperation_images">
            <template v-if="item.link">
              <a :href="item.link" target="_blank">
                <img class="cooperation_images_item" :src="item.img" :alt="item.name" />
              </a>
            </template>
            <template v-else>
              <img class="cooperation_images_item" :src="item.img" :alt="item.name" />
            </template>
          </template>
        </div>
        <div class="footer_nav">
          <a class="footer_logo" href="/" target="_blank">
            <img class="footer_logo_image" :src="footer_config.pc_logo" />
          </a>
          <div class="footer_nav_info">
            <div class="footer_nav_item">
              <span class="footer_nav_line">|</span>
              <template v-for="(item, index) in footer_config.nav">
                <a :href="item.link" class="footer_nav_a"
                 ref="nav_title" @mouseenter="onMouseenter(index)" @mouseleave="onMouseleave(index)">
                  {{ item.title }}
                </a>
                <span class="footer_nav_line">|</span>
              </template>
            </div>
            <div class="footer_nav_info_line"></div>
            <div class="footer_introduce">
              <div class="footer_introduce_text">{{ footer_config.introduce }}</div>
              <div class="footer_introduct_images">
                <template v-for="(item, index) in footer_config.contact">
                  <div @click="clickContact(item)">
                    <img ref="item_image" :src="item.img" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <a class="copyright" href="https://beian.miit.gov.cn/" target="_blank">{{ footer_config.copyright }}</a>
        <div class="other_nav">
          <span class="other_nav_icon">>></span>
          <template v-for="(item, index) in footer_config.other">
            <template v-if="item.link">
              <a :href="item.link">{{ item.title }}</a>
            </template>
            <template v-else>
              {{ item.title }}
            </template>
            <template v-if="index != footer_config.other.length - 1"><span class="other_nav_other_line">/</span></template>
          </template>
        </div>
      </div>
    </div>
    <contact ref="contact" style="z-index: 100"></contact>
  </div>
</template>
<script>
import contact from "@/components/contact.vue";

export default {
  components: {
    contact
  },
  data() {
    return {
      footer_config: {
        taps: [
          {
          "title": "公司地址",
          "desc": "中國香港",
          "desc_two": "新界屯門青發街18號",
          "img": "https://api.genghis.hk/uploads/images/20240515/20240515112228a5a101789.png"
        }, {
          "title": "疑難問題",
          "desc": "Info@genghis.hk",
          "desc_two": "任何問題郵件聯繫我們致力為您解決",
          "img": "https://api.genghis.hk/uploads/images/20240515/202405151123078e4f84205.png"
        }, {
          "title": "聯繫我們",
          "desc": "電話咨詢: +852 6213 9561",
          "desc_two": "WhatsApp: +852 6213 9561",
          "img": "https://api.genghis.hk/uploads/images/20240515/20240515112318488567912.png"
        }
        ],
        cooperation_images: [
        {
				"link": "",
				"name": "",
				"img": "https://api.genghis.hk/uploads/images/20240518/202405181725456ead40352.png"
			}, {
				"link": "",
				"name": "",
				"img": "https://api.genghis.hk/uploads/images/20240518/202405181725453f5442964.png"
			}, {
				"link": "",
				"name": "",
				"img": "https://api.genghis.hk/uploads/images/20240518/202405181725456ead40352.png"
			}, {
				"link": "",
				"name": "名稱",
				"img": "https://api.genghis.hk/uploads/images/20240518/20240518172545b25006152.png"
			}, {
				"link": "",
				"name": "",
				"img": "https://api.genghis.hk/uploads/images/20240518/202405181725459ae4b0697.png"
			}
        ],
        nav: [
        {
				"title": "首頁",
				"link": "/"
			}, {
				"title": "走進鐵木",
				"link": "/self"
			}, {
				"title": "購買材料",
				"link": "/product"
			}, {
				"title": "送貨方式",
				"link": "/send"
			}, {
				"title": "品質保障",
				"link": "/quality"
			}, {
				"title": "聯係我們",
				"link": "/about"
			}
        ],
        pc_logo: "https://api.genghis.hk/uploads/images/20240511/20240511222150ae5907264.png",
        introduce: "GENGHIS香港鐵木建材有限公司自成立以來，一直為廣大客戶提供安全、可靠、優質的建築材料及施工用品，協助建築公司、房地產開發商、政府部門、機構組織等實現各種建築計畫，為各類建築工程和施工項目供應一系列高品質的材料及設備，以確保建築項目的順利完成、安全可靠和長期耐用性。",
        copyright: "Copyright © 2024 GENGHIS All Right Reserved. 版權所有，不得轉載",
        other: [
        {
				"title": "香港站",
				"link": ""
			}, {
				"title": "深圳站",
				"link": ""
			}, {
				"title": "廣州站",
				"link": ""
			}
        ],
        contact: [{
				"link": "/about",
				"img": "https://api.genghis.hk/uploads/images/20240515/20240515112228a5a101789.png"
			}, {
				"link": "emit",
				"img": "https://api.genghis.hk/uploads/images/20240515/202405151123078e4f84205.png"
			}, {
				"link": "https://api.whatsapp.com/send/?phone=85262139561&text&type=phone_number&app_absent=0",
				"img": "https://api.genghis.hk/uploads/images/20240515/2024051511241810d164228.png"
			}],
        currentIndex: null
      },
      header_config:{
        whatapp:"85262139561"
      },
      visible: false
    };
  },
  created() {
   
  },
  mounted() {
    this.currentPath();
  },
  
  methods: {
    currentPath() {
      var that = this;

      var path = this.$route.path;
      // nav_title
      var footer_config_nav = that.footer_config.nav;
      for (let index = 0; index < footer_config_nav.length; index++) {
        if (footer_config_nav[index].link == path) {
          //   that.$refs.nav_title[index].style.color = "#314f73";
          that.$refs.nav_title[index].style.fontWeight = "bold";
          that.$refs.nav_title[index].style.fontSize = "18px";
          that.currentIndex = index;
        }
      }
    },
    showEmalil(){
      this.$refs.contact.show();
    },
    clickContact(item) {
      var that = this;
      if (item.link == "emit") {
        // that.visible=true

        that.$refs.contact.show();
      } else {
        window.open(item.link);
      }
    },
    onMouseenter(index) {
      var that = this;
      if (that.currentIndex == index) {
        return;
      }
      that.$refs.nav_title[index].style.color = "#314f73";
      //   that.$refs.nav_title[index].style.fontSize = "18px";
      that.$refs.nav_title[index].style.fontWeight = "800";
    },
    onMouseleave(index) {
      var that = this;
      if (that.currentIndex == index) {
        return;
      }

      that.$refs.nav_title[index].style.color = "";
      that.$refs.nav_title[index].style.fontSize = "";
      that.$refs.nav_title[index].style.fontWeight = "";
    }
  }
};
</script>

<style lang="scss" scoped>
// .footer {
//     background: #fff;
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     width: 100%;
//     transform: translateX(-50%);
// }
.footer_nav {
  display: flex;
  align-items: center;
  .footer_logo {
    width: 300px;
    margin-right: 50px;
    .footer_logo_image {
      width: 100%;
    }
  }
  .footer_nav_info {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    .footer_nav_item {
      color: #393939;
      font-family: HarmonyOS_Sans_SC_Light;
      display: flex;
      .footer_nav_a {
        flex: 1;
        text-align: center;
        display: inline-block;
        letter-spacing: 2px;
      }
    }
    .footer_nav_info_line {
      border-top: 1px solid #d0d0d0;
      margin: 20px 0 15px 0;
    }
    .footer_introduce {
      display: flex;
      .footer_introduce_text {
        font-size: 12px;
        flex: 1;
        padding-right: 30px;
        line-height: 18px;
        letter-spacing: 2px;
      }
      .footer_introduct_images {
        display: flex;
        width: 250px;
        justify-content: space-around;
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

.footer-copyright {
  background: #d0d0d0;
  display: flex;
  line-height: 40px;
  font-family: HarmonyOS_Sans_SC_Light;
  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;
  .other_nav_icon {
    padding-right: 5px;
  }
  .copyright,
  .other_nav {
    flex: 1;
    text-align: center;
    .other_nav_other_line {
      padding: 0 5px;
    }
  }
}

.cooperation_images {
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  .cooperation_images_item {
    width: 100px;
    margin: 0 20px;
  }
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.footer_taps {
  display: flex;

  .footer_taps_item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer_taps_img {
      width: 70px;
      height: 70px;
    }

    .footer_taps_title {
      margin-top: 25px;
      font-family: HarmonyOS_Sans_SC_Bold;
      font-weight: bold;
      font-size: 22px;
      color: #002557;
      letter-spacing: 8px;
    }

    .footer_taps_line {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 40px;
      border-top: 2px solid #2c7668;
    }

    .footer_taps_desc,
    .footer_taps_desc_two {
      font-size: 16px;
      font-family: HarmonyOS_Sans_SC_Light;
      line-height: 24px;
      color: #231815;
      letter-spacing: 2px;
    }
  }
}
</style>
