import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import about from '../views/about.vue'
import self from '../views/self.vue'
import product from '../views/product.vue'
import send from '../views/send.vue'
import quality from '../views/quality.vue'
import login from '../views/login.vue'
import register from '../views/register.vue'
import forgot from '../views/forgot.vue'
import cart from '../views/cart.vue'
import details from '../views/details.vue'
import ourself from "../views/ourself.vue";
import forgot_email from "../views/forgot_email.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "index",
    component: index
  },
  {
    path: "/about",
    name: "about",
    component: about
  },
  {
    path: "/self",
    name: "self",
    component: self
  },
  {
    path: "/send",
    name: "send",
    component: send
  },
  {
    path: "/quality",
    name: "quality",
    component: quality
  },
  {
    path: "/product",
    name: "product",
    component: product
  },
  {
    path: "/details/:id",
    name: "details",
    component: details
  },
  {
    path: "/login",
    name: "login",
    component: login
  },
  {
    path: "/register",
    name: "register",
    component: register
  },
  {
    path: "/forgot",
    name: "forgot",
    component: forgot
  },
  {
    path: "/forgot_email",
    name: "forgot_email",
    component: forgot_email
  },
  
  {
    path: "/cart",
    name: "cart",
    component: cart
  },
  {
    path: "/ourself",
    name: "ourself",
    component: ourself
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
