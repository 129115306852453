/**
 * iView Admin Pro 业务配置
 * */

// const roterPre = "/admin";

const Setting = {
  // roterPre,
  /**
   * 基础配置
   * */
  // 路由模式，可选值为 history 或 hash
  routerMode: "history",
  // 页面切换时，是否显示模拟的进度条
  showProgressBar: false,
  debug: true, //调试模式
  F12: true, //true为允 许打开F12
  // 接口请求地址

  apiBaseURL: "https://api.genghis.hk/webapi/",
  // apiBaseURL: "http://tiemu.api.hk.gapsong.cn/pcapi/",
  // apiBaseURL: "http://tiemu.com/webapi/",

  //本地测试
  identity: "admin",

  // 接口请求返回错误时，弹窗的持续时间，单位：秒
  modalDuration: 5,
  // 接口请求返回错误时，弹窗的类型，可选值为 Message 或 Notice
  errorModalType: "Notice",
  // Cookies 默认保存时间，单位：天
  cookiesExpires: 10,
  /**
   * 多语言配置
   * */
  i18n: {
    // 默认语言
    default: "zh-CN",
    // 是否根据用户电脑配置自动设置语言（仅第一次有效）
    auto: false
  }
};

export default Setting;
