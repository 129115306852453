<template>
  <div class="">
    <appHeader></appHeader>
    <appNav></appNav>
    <div class="app-max-container">
      <img :src="image" class="banner" />
      <div class="one">
        <div class="one_left">
          <img class="one_left_img" :src="one.left.image" />
          <div class="one_sefl_box">
            <p class="title">{{ one.left.self_lst.title }}</p>
            <span class="desc" v-for="item in one.left.self_lst.item">
              <img src="@/assets/icon/succes.png" />
              {{ item }}
            </span>
          </div>
          <div class="one_sefl_other">
            <p class="title">{{ one.left.other_lst.title }}</p>
            <span class="desc" v-for="item in one.left.other_lst.item">
              <img src="@/assets/icon/err.png" />
              {{ item }}
            </span>
          </div>
        </div>
        <div class="one_right">
          <div class="one_right_title">
            <span class="stitle">{{ one.right.stitle }}</span>
            <span class="title">{{ one.right.title }}</span>
          </div>
          <div class="one_right_item">
            <div class="one_right_item_item" v-for="item in one.right.lst">
              <img class="one_right_item_item_img" :src="item.image" />
              <p class="desc">{{ item.desc }}</p>
              <p class="title">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="two">
        <div class="two_image">
          <img :src="two.image" class=two_image_image />
        </div>
        <div class="three_container_right_info">
          <div class="two_container_item">
            <div class="title_box">
              <div class="container_item_title">
                <p class="title">{{ two.title }}</p>
                <span class="title_en">{{ two.title_en }}</span>
              </div>
            </div>
            <div class="desc">{{ two.desc }}</div>
            <div class="desc">{{ two.desc_two }}</div>
          </div>
          <div class="image_item">
            <div class="img_box" v-for="item in two.images">
              <img :src="item" />
            </div>
          </div>
        </div>
      </div>
      <div class="three">
        <div class="three_left">
          <div class="title_info">{{ three.left.title }}</div>
          <div class="desc">{{ three.left.title_desc }}</div>
          <div class="send_time">
            <div class="send_time_title">{{ three.left.work.title }}</div>
            <div class="send_time_info">
              <p class="work_day">{{ three.left.work.work_day }}</p>
              <p class="work_time">{{ three.left.work.work_time }}</p>
            </div>
          </div>
          <div class="title_info">{{ three.left.title_two }}</div>
          <div class="desc">{{ three.left.title_two_desc }}</div>
          <div class="desc">{{ three.left.title_two_desc_two }}</div>
          <div class="desc">{{ three.left.title_two_desc_three }}</div>
          <div class="desc errdesc">
            {{ three.left.desc_err }}
          </div>
        </div>
        <div class="three_right">
          <div class="three_right_title">{{ three.right.title }}</div>
          <div class="three_right_info">
            <div class="three_right_info_item" v-for="item in three.right.desc_item">
              <span class="three_right_info_title">{{ item.title }}</span>
              <p class="three_right_info_desc" v-for="desc in item.desc" v-html="desc"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="four">
      <div class="app-max-container">
        <div class="four_container">
          <div class="four_container_left">
            <div class="two_container_item">
              <div class="title_box">
                <div class="container_item_title">
                  <p class="title">{{ four.title }}</p>
                  <span class="title_en">{{ four.title_en }}</span>
                </div>
              </div>
              <div class="desc" v-for="desc in four.desc">{{ desc }}</div>
            </div>
          </div>
          <div class="four_container_right">
            <div class="send_email">
              <div class="four_container_right_hedaer">
                <img :src="four.send_logo" />
                <span>{{ four.send_title }}</span>
              </div>
              <el-input class="input-send" v-model="user.username" placeholder="如何稱呼您"></el-input>
              <el-input class="input-send" v-model="user.email" placeholder="電子郵箱"></el-input>
              <el-input class="input-send" v-model="user.phone" placeholder="聯係電話 例如 +852622139561"></el-input>
              <el-input class="input-send" v-model="user.reason" placeholder="您的需求"></el-input>
              <el-button round class="send_btn" @click="submit">提交</el-button>
            </div>
          </div>
        </div>
      </div>
      <img class="sned_bg" :src="four.send_bg" />
    </div>
    <div class="five">
      <div class="app-max-container">
        <div class="five_container">
          <div class="five_container_item" v-for="(item, index) in five" :key="index">
            <img class="five_container_img" v-if="item.image && index % 2 == 0" :src="item.image" />
            <div class="five_container_ifno">
              <div class="five_container_ifno_title">
                <img class="five_container_icon" :src="item.icon" />
                {{ item.title }}
              </div>
              <div class="five_container_ifno_desc">
                {{ item.desc }}
              </div>
              <div class="five_container_ifno_desc">
                {{ item.desc_two }}
              </div>
            </div>
            <img class="five_container_img" v-if="item.image && index % 2 == 1" :src="item.image" />
          </div>
        </div>
      </div>
    </div>
    <appLink></appLink>

    <appFooter></appFooter>
  </div>
</template>

<script>
import { getSend, addIndexUserLinkedtApi } from "@/api/index.js";
import appHeader from "@/components/header.vue";
import appFooter from "@/components/footer.vue";
import appNav from "@/components/nav.vue";
import appLink from "@/components/link.vue";

export default {
  components: {
    appHeader,
    appFooter,
    appNav,
    appLink
  },
  data() {
    return {
      user: {
        username: "",
        email: "",
        phone: "",
        reason: ""
      },
      "image": "https://api.genghis.hk/uploads/images/test/sss.jpg",
      "one": {
            "left": {
                "image": "https://api.genghis.hk/uploads/images/test/carss.png",
                "self_lst": {
                    "title": "智能訂購",
                    "item": [
                        "智能訂購",
                        "一站購齊",
                        "24小時智能報價"
                    ]
                },
                "other_lst": {
                    "title": "傳統採購",
                    "item": [
                        "提前訂貨",
                        "貨比三家",
                        "工作時間限制"
                    ]
                }
            },
            "right": {
                "stitle": "節省更多金錢",
                "title": "致力於提供安全、可靠、優質的建築材料及各式施工用品",
                "lst": [
                    {
                        "image": "https://api.genghis.hk/uploads/images/test/001.png",
                        "desc": "市面最低價格",
                        "title": "智能比價"
                    },
                    {
                        "image": "https://api.genghis.hk/uploads/images/test/002.png",
                        "desc": "無中間商差價",
                        "title": "一手貨源"
                    },
                    {
                        "image": "https://api.genghis.hk/uploads/images/test/003.png",
                        "desc": "有助資金周轉",
                        "title": "分期付款"
                    },
                    {
                        "image": "https://api.genghis.hk/uploads/images/test/004.png",
                        "desc": "節約倉儲成本",
                        "title": "分批出料"
                    }
                ]
            }
        },
        "two": {
            "image": "https://api.genghis.hk/uploads/images/20240518/202405181748325710b4520.png",
            "title": "物流配送",
            "title_en": "PROFESSIONAL DELIVERY",
            "desc": "Genghis 致力於提供高效率和可靠性的物流配送服務，以確保各類貨物能夠按時、完好地送達到客戶指定的目的地。為了更好地服務客戶，我們特設免運送費用的服務，只要客戶的訂單金額達到$2000以上，送抵地點為香港市區，即可免除運送費用，幫助客戶節省運輸成本，同時提高客戶的整體滿意度，讓客戶能夠更輕鬆地購買所需的建築材料。憑藉多年來在物流管理方面積累的豐富經驗，我們的專業物流團隊能夠對各類貨物進行全面的監管和管理，確保貨物在整個供應鏈中能夠得到妥善的處理和運送，從而為客戶提供更可靠、安全的配送服務。",
            "desc_two": "此外，我們的物流網絡覆蓋範圍廣泛，能夠提供快速供貨、緊急即送服務，在最短的時間內安排貨物的運送，確保客戶能夠按計劃進行施工，避免因物料供應不及時而造成的工程延誤。我們承諾為客戶提供優質的物流配送服務，確保客戶的業務運轉順利，項目進展如期，讓客戶在施工過程中能夠更加放心、便捷。",
            "images": [
                "https://api.genghis.hk/uploads/images/20240515/20240515165448308034313.png",
                "https://api.genghis.hk/uploads/images/20240515/20240515165657d434e6827.png",
                "https://api.genghis.hk/uploads/images/20240515/20240515165728bf4307292.png",
                "https://api.genghis.hk/uploads/images/20240515/202405151658344f1b63012.png",
                "https://api.genghis.hk/uploads/images/20240515/20240515165745bdf975027.png"
            ]
        },
      "three": {
            "left": {
                "title": "送貨上門服務",
                "title_desc": "任何訂單均可提供，貨款$2000起即可免運送費用（香港市區）",
                "work": {
                    "title": "送貨時間",
                    "work_day": "星期一至星期六",
                    "work_time": "08:30-18:00（假日除外）"
                },
                "title_two": "送貨流程",
                "title_two_desc": "1. 物流團隊會在當日與客戶聯絡，說明預計送貨時間，了解送貨地點的注意 事項/相關規定。",
                "title_two_desc_two": "2. 當司機到達送貨地點時，會與收件方核對地點及收貨人資料再進行交收。",
                "title_two_desc_three": "3. 視貨物數量安排物流助手，可跟上落貨，保證物料按時完整到達目的地。",
                "desc_err": "* 在特殊情況下，如收貨地址無人接收貨物，司機將聯繫客戶確認是否將貨件放置於門前、交給管理處代為保管，或重新安排送貨（可能會產生額外的配送費用）。如貨件放置於門前，我們將拍攝照片作為證據保存。請注意，如出現貨物丟失情況，本公司車隊保留不承擔責任的權利。"
            },
            "right": {
                "title": "收貨規則,注意事項",
                "desc_item": [
                    {
                        "title": "收貨規則",
                        "desc": [
                            "貨款滿 $2000、香港市區交收可免運費送貨，否則另需加收 $150",
                            "以下地區須加收附加費 (不論貨款金額多少)： <br/>『 東涌，機場 +$200/單 』 『 愉景灣 +$300/單 』",
                            "基本運費已包括上樓費用 (行樓梯、轉電梯、推斜路需另收運費，送貨前客人需提供相片或影片作運費報價)"
                        ]
                    },
                    {
                        "title": "注意事項",
                        "desc": [
                            "1. 如所住的地區沒有免費停車位置供泊車，須另外支付相關費用。",
                            "2. 如客人購買的物料體積過大而未能入電梯，將安排地下交收。"
                        ]
                    }
                ]
            }
        },
     "four": {
            "title": "客戶服務",
            "title_en": "CUSTOMER SERVICE",
            "desc": [
                "我們堅持為客戶提供全方位、貼心的客戶服務，專業的建議和產品選擇、周到的售後支援。無論客戶在購買過程中遇到任何問題或需求，客服團隊都會全力以赴，盡心盡力地為客戶提供及時、有效的幫助和支持。客戶可以輕鬆通過 WhatsApp 或電話聯繫我們專屬的「私人訂料秘書」，在與專業人員的交流中獲得所需的產品資訊和訂購指導，從而為客戶提供更加個性化、定制化的服務體驗。",
                "我們的客服團隊成員擁有豐富的行業知識和專業技能，經過專業培訓和實戰經驗的累積，能夠為客戶提供高水準的建議和解決方案。當客戶在建材選擇、施工技術或其他方面遇到困難，我們都將通過積極溝通和深入了解客戶需求，提供量身定制的建議和解決方案，幫助客戶克服困難，做出明智的決策，從而確保客戶在使用我們的高品質建築材料時獲得最佳效果。",
                "我們的服務承諾不僅僅止於產品銷售，更注重與客戶之間的深入溝通和良好互動，力求為客戶創造愉悅和輕鬆的購物體驗。我們將繼續堅持服務至上的理念，通過持續不斷地優化和提升服務品質，為客戶打造更加便捷、高效的建材購物環境，實現客戶需求與我們的專業服務完美匹配，為客戶的建築項目注入更多價值和信心。"
            ],
            "send_bg": "https://api.genghis.hk/uploads/images/test/send_bg.png",
            "send_logo": "https://api.genghis.hk/uploads/images/test/snef_log.png",
            "send_title": "聯繫我們"
        },
      "five": [
            {
                "title": "合規企業",
                "image": "https://api.genghis.hk/uploads/images/test/t1.png",
                "icon": "https://api.genghis.hk/uploads/images/test/wyj_bs.png",
                "desc": "優質可靠的產品供應，快速供應鏈",
                "desc_two": "減少產品運輸時間，符合進口的規範和標準，避免合規性問題"
            },
            {
                "title": "企業/機構",
                "image": "https://api.genghis.hk/uploads/images/test/t2.png",
                "icon": "https://api.genghis.hk/uploads/images/test/shou_bs.png",
                "desc": "享受最優惠的價格 · 最優質的建築材料 · 最完善的售後服務",
                "desc_two": ""
            },
            {
                "title": "國際知名品牌/國內一線產房",
                "image": "https://api.genghis.hk/uploads/images/test/t3.png",
                "icon": "https://api.genghis.hk/uploads/images/test/shu_bs.png",
                "desc": "提供種類齊全、價格實惠、符合國際標準的高品質建築材料",
                "desc_two": ""
            },
            {
                "title": "企業/機構",
                "image": "https://api.genghis.hk/uploads/images/test/t4.png",
                "icon": "https://api.genghis.hk/uploads/images/test/che_bs.png",
                "desc": "自設貨倉，貨源穩定",
                "desc_two": "一手貨源、智能比價、優質物流，極具競爭力的價格和專業的客戶服務"
            }
        ],
      send_state: true
    };
  },
  created() {
    // getSend().then((res) => {
    //   this.image = res.data.image;
    //   this.one = res.data.one;
    //   this.two = res.data.two;

    //   this.three = res.data.three;
    //   this.four = res.data.four;
    //   this.five = res.data.five;
    // });
  },
  methods: {
    submit() {
      var that = this;
      if (this.send_state) {
        if (this.user.username == "") {
          this.$message.error("如何稱呼您");
          return;
        }
        if (this.user.email == "" && this.user.phone) {
          this.$message.error("請輸入電子郵箱 或 聯係電話");
          return;
        }

        var email_reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.user.email);

        var mobile_reg = /^(\+?\d{1,4})?\s?-?\s?\(?(\d{3})\)?\s?-?\s?(\d{3})\s?-?\s?(\d{4})$/.test(this.user.phone);
        if (!email_reg) {
          that.$message.error("请輸入正確郵箱");
          return;
        }
        if (!mobile_reg) {
          that.$message.error("请輸入正確手機號碼");
          return;
        }

        if (this.user.reason == "") {
          this.$message.error("請輸入您的需求");
          return;
        }
        this.send_state = false;
        addIndexUserLinkedtApi(that.user).then((res) => {
          this.send_state = true;
          if (res.code == 200) {
            this.user = {
              username: "",
              email: "",
              phone: "",
              reason: ""
            };
            that.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.send_email {
  width: 450px;

  .el-input__inner {
    border-radius: 50px;
    border: 1px solid #000;
  }
}
</style>
<style lang="scss" scoped>
.five {
  margin-top: 30px;

  .five_container {
    padding: 30px;
    display: flex;

    .five_container_item {
      .five_container_img {
        width: 300px;
        height: 200px;
        display: flex;
      }

      .five_container_ifno {
        background: #002557;
        color: #fff;
        height: 200px;

        .five_container_ifno_title {
          display: flex;
          margin-bottom: 20px;
          align-items: center;
          font-weight: bold;
          font-size: 16px;
          padding-left: 40px;
          padding-top: 50px;

          .five_container_icon {
            margin-right: 10px;
            width: 30px;
          }
        }

        .five_container_ifno_desc {
          padding: 0 40px;
          margin-bottom: 20px;
          line-height: 20px;
          font-size: 13px;
        }
      }
    }
  }
}

.send_btn {
  margin-top: 50px;
  width: 70%;
  margin-left: 15%;
  background: #002557;
  color: #fff;
  border: none;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 10px;
}

.input-send {
  margin-top: 30px;
}

.four_container_right {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 60px;
}

.four_container_right_hedaer {
  display: flex;
  align-items: end;

  span {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    padding-left: 30px;
  }
}

.four {
  background: #cfdae8;
  position: relative;
  margin-top: 50px;

  .sned_bg {
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    right: 0;
    z-index: 0;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .four_container {
    padding: 30px;
    display: flex;

    .four_container_left,
    .four_container_right {
      flex: 1;
      position: relative;
      z-index: 10;
    }

    .four_container_left {
      padding-right: 40px;

      .desc {
        margin-top: 20px;
        line-height: 24px;
        letter-spacing: 0.4px;
        font-size: 14px;
      }
    }
  }
}

.three_right_info {
  background-color: #f6f6f6;
  border: 1px dashed #002557;
  padding: 40px 35px;

  .three_right_info_item {
    margin-bottom: 30px;

    .three_right_info_title {
      font-size: 20px;
      color: #fff;
      background: #002557;
      padding: 8px 20px;
      display: inline-block;
      margin-bottom: 20px;
    }

    .three_right_info_desc {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 15px;
    }
  }
}

.three_right_title {
  border: 5px solid #002557;
  color: #002557;
  font-size: 17px;
  line-height: 60px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 40px;
}

.send_time {
  display: flex;
  flex-direction: column;
  border: 1px solid #000000;
  margin-bottom: 20px;

  .send_time_title {
    color: #000;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    background-color: #f6f6f6;
    border-bottom: 1px solid #000;
    line-height: 40px;
  }

  .send_time_info {
    display: flex;
    line-height: 40px;
    text-align: center;

    .work_day {
      width: 40%;
      border-right: 1px solid #000;
    }

    .work_time {
      width: 60%;
    }
  }
}

.three_left {
  .title_info {
    font-size: 18px;
    font-weight: bold;
    color: #002557;
    border-bottom: 2px solid #002557;
    line-height: 50px;
    margin-bottom: 20px;
  }

  .desc {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 20px;
  }

  .errdesc {
    font-size: 16px;
    color: #ff0000;
    line-height: 28px;
  }
}

.three {
  margin-top: 50px;
  padding: 0 30px;
  display: flex;

  .three_left,
  .three_right {
    flex: 1;
  }

  .three_right {
    margin-left: 60px;
    padding-top: 15px;
  }
}

.image_item {
  display: flex;
  justify-content: space-between;

  .img_box {
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: flex;

    img {
      width: 100%;
      border-radius: 10px;
      border: 2px solid #000000;
    }
  }
}

.three_container_right_info {
  margin-left: 40px;
  flex: 1;
}

.two {
  display: flex;
  margin-top: 60px;
  padding: 0 30px;
}

.two_image {
  width: 400px;
  display: flex;
  align-items: end;

  .two_image_image {
    width: 100%;
  }
}

.three_container_right_info {
  .two_container_item {
    padding: 30px 0;

    .desc {
      margin-top: 20px;
      color: #474748;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      margin-bottom: 20px;
      font-weight: 400;
    }

    .desc_two {
      margin-bottom: 0;
    }
  }
}

.title_box {
  display: inline-block;
  border-bottom: 1px solid #000000;

  .container_item_title {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
  }

  .title {
    font-size: 42px;
    font-weight: bold;
  }

  .title_en {
    font-size: 26px;
    padding-left: 5px;
    font-weight: initial;
  }

  .desc {
    margin-top: 10px;
    color: #474748;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }
}

.one_right_item {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .one_right_item_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .one_right_item_item_img {
      width: 50px;
      height: 50px;
    }

    .desc {
      margin-top: 30px;
      font-size: 20px;
    }

    .title {
      margin-top: 10px;
      font-size: 32px;
      font-weight: bold;
      color: #002557;
    }
  }
}

.one_right {
  flex: 1;

  .one_right_title {
    background: #002557;
    border-radius: 20px;
    display: flex;
    padding: 5px 40px;
    align-items: flex-end;
    justify-content: center;

    .stitle,
    .title {
      display: inline-block;
    }

    .stitle {
      font-weight: bold;
      font-size: 20px;
      color: #fff08b;
    }

    .title {
      padding-left: 10px;
      color: #fff;
      font-size: 14px;
    }
  }
}

.banner {
  display: flex;
  width: 100%;
}

.one {
  display: flex;
  margin-top: 80px;
  padding: 0 30px;

  .one_left {
    width: 600px;
    position: relative;

    .one_left_img {
      width: 130px;
      height: 180px;
    }

    .one_sefl_box,
    .one_sefl_other {
      position: absolute;
      width: 230px;
      border-radius: 10px;
      background: #002557;
      top: 0;
      left: 0;
      color: #fff;
      display: flex;
      flex-direction: column;
      text-align: left;
      text-align: center;

      .title {
        font-size: 30px;
        color: #fff;
        line-height: 70px;
        font-weight: bold;
      }

      .desc {
        border-top: 1px solid #fff;
        line-height: 50px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 40px;

        img {
          margin-right: 10px;
        }
      }
    }

    .one_sefl_box {
      left: 125px;
      top: -15px;
      z-index: 1;

      .desc {
        line-height: 55px;
      }
    }

    .one_sefl_other {
      left: 340px;
      width: 200px;
      top: 0;
      background: #c4c4c4;
      color: #5e5e5e;

      .title {
        color: #5e5e5e;
        font-size: 24px;
      }

      .desc {
        font-size: 16px;
        color: #5e5e5e;
        line-height: 45px;
      }
    }
  }
}
</style>
