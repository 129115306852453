<template>
  <div class="">
    <appHeader></appHeader>
    <appNav></appNav>
    <div class="banner" ref="banner_box">
      <div v-for="(item, index) in banner" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
        <div class="carousel-diy-box">
          <div class="carousel_diy_item">
            <template v-for="item_img in item.image">
              <img class="item_img" :src="item_img" />
            </template>
          </div>
          <div class="carousel_diy_item">
            <template v-for="item_img in item.image">
              <img class="item_img" :src="item_img" />
            </template>
          </div>
        </div>
        <div class="banner_title">
          <p>{{ item.banner_title }}</p>
          <span>{{ item.banner_desc }}</span>
        </div>
      </div>
    </div>
    <div class="app-max-container">
      <div class="one_container_item container_item">
        <div class="container_item_title">
          <p class="title_yy">{{ one_container.title_yy }}</p>
          <p class="title_en">{{ one_container.title_en }}</p>
          <span class="title_zn">{{ one_container.title }}</span>
        </div>
        <div class="four_info">
          <div v-for="item in one_container.four_info" class="four_info_item">
            <div class="four_info_container">
              <p class="four_info_container_title">
                <span>{{ item.title }}</span>
              </p>
              <p class="four_info_container_desc" v-html="item.desc"></p>
            </div>
          </div>
        </div>
        <div class="one_container_body">
          <div class="one_container_body_info">
            <p class="mini_title">{{ one_container.four_body.mini_title }}</p>
            <p class="title">{{ one_container.four_body.title }}</p>
            <span class="line"></span>
            <p class="desc">{{ one_container.four_body.desc }}</p>
            <a class="btn" :href="one_container.four_body.link">{{ one_container.four_body.link_text }}</a>
          </div>
          <img class="one_container_body_image" :src="one_container.four_body.image" />
        </div>
        <div class="one_container_product">
          <a class="product" v-for="item in one_container.four_product" :href="'/details/' + item.id">
            <p class="product_title">{{ item.title }}</p>
            <img :src="item.image" class="product_image" />
          </a>
        </div>
      </div>
    </div>

    <div style="background: #f1f1f1">
      <div class="app-max-container">
        <div class="two_container_item" v-if="tow_container.length == 2">
          <div class="tow_container_one tow_container_item">
            <img :src="tow_container[0].image" />
            <div class="tow_container_one_info">
              <p class="tow_container_one_info_title">
                {{ tow_container[0].title }}
                <span>{{ tow_container[0].title_en }}</span>
              </p>
              <span class="line"></span>
              <p class="desc" v-html="tow_container[0].desc"></p>
              <div class="hs">
                <div class="div_one">
                  <p class="div_one_title">{{ tow_container[0].mx }}</p>
                  <p class="div_one_title_desc" v-html="tow_container[0].mx_desc"></p>
                </div>
                <div class="hz_zk">
                  {{ tow_container[0].number }}
                </div>
              </div>
            </div>
          </div>
          <div class="tow_container_tow tow_container_item">
            <div class="tow_container_item_desc">
              <p>{{ tow_container[1].desc }}</p>

              <a class="btn" :href="tow_container[1].link">{{ tow_container[1].link_text }}</a>
            </div>
            <img :src="tow_container[1].image" />
          </div>
        </div>
      </div>
    </div>
    <div class="three_container">
      <div class="three_container_img" :style="'background-image: url(' + three_container.image + ')'">
        <div class="app-max-container three_container_container">
          <div class="three_container_item" v-for="item in three_container.lst">
            <div class="three_container_item_logotype" v-if="item.logotype">{{ item.logotype }}</div>
            <div class="three_container_item_image_logotype">
              <img class="three_container_item_image" :src="item.image" />
            </div>
            <div class="three_container_item_info">
              <p class="title">{{ item.title }}</p>
              <span class="line"></span>
              <p class="desc">{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="app-max-container">
      <div class="four_container_item container_item">
        <div class="container_item_title">
          <p class="title_yy">{{ four_container.title_yy }}</p>
          <p class="title_en">{{ four_container.title_en }}</p>
          <span class="title_zn">{{ four_container.title }}</span>
        </div>
      </div>
      <div class="four_container_body">
        <img class="four_container_image" :src="four_container.image" />
        <div class="four_container_lst">
          <div class="four_container_lst_item" v-for="(item, index) in four_container.lst" :key="index">
            <div class="title">
              <span>{{ item.title }}</span>
              <img :src="item.image" />
            </div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <div class="four_container_foorer">
        <div class="four_container_foorer_left">
          <img class="four_container_foorer_left_img" :src="four_container.footer.image" />
          <p class="desc">{{ four_container.footer.desc }}</p>
          <a class="btn" :href="four_container.footer.link">{{ four_container.footer.link_text }}</a>
        </div>
        <div class="four_container_foorer_right">
          <div class="ts">{{ four_container.footer.ts }}</div>
          <div class="four_container_foorer_right_item" v-for="item in four_container.footer.lst">
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
        <div class="index_last">節省更多時間和金錢</div>
      </div>
    </div>

    <div class="three_container">
      <div class="three_container_img" :style="'background-image: url(' + five_container.image + ')'">
        <div class="app-max-container three_container_container five_container_container">
          <div class="three_container_item five_container_item" v-for="item in five_container.lst">
            <img class="three_container_item_image five_container_item_image" :src="item.image" />
            <div class="three_container_item_info five_container_item_info">
              <p class="title">{{ item.title }}</p>
              <span class="line"></span>
              <p class="desc">{{ item.desc }}</p>
              <a class="btn" :href="item.link">{{ item.link_text }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <appLink></appLink>

    <appFooter></appFooter>
  </div>
</template>

<script>
import { getIndex } from "@/api/index.js";
import appHeader from "@/components/header.vue";
import appFooter from "@/components/footer.vue";
import appNav from "@/components/nav.vue";
import appLink from "@/components/link.vue";

export default {
  components: {
    appHeader,
    appFooter,
    appNav,
    appLink
  },
  data() {
    return {
      banner: [
        {
          "image": [
            "https://api.genghis.hk/uploads/images/test/banner/1.png",
            "https://api.genghis.hk/uploads/images/test/banner/2.png",
            "https://api.genghis.hk/uploads/images/test/banner/3.png",
            "https://api.genghis.hk/uploads/images/test/banner/4.png",
          ],
          "banner_title": "我們所提供的產品符合最高標準",
          "banner_desc": "品質控制團隊對每一批貨物進行嚴格的檢驗，使每一款產品的品質和性能都符合客戶的期望。"
        },
        {
          "image": [
            "https://api.genghis.hk/uploads/images/test/banner/1.png",
            "https://api.genghis.hk/uploads/images/test/banner/2.png",
            "https://api.genghis.hk/uploads/images/test/banner/3.png",
            "https://api.genghis.hk/uploads/images/test/banner/4.png",
          ],
          "banner_title": "我們所提供的產品符合最高標準",
          "banner_desc": "品質控制團隊對每一批貨物進行嚴格的檢驗，使每一款產品的品質和性能都符合客戶的期望。"
        },
        {
          "image": [
            "https://api.genghis.hk/uploads/images/test/banner/1.png",
            "https://api.genghis.hk/uploads/images/test/banner/2.png",
            "https://api.genghis.hk/uploads/images/test/banner/3.png",
            "https://api.genghis.hk/uploads/images/test/banner/4.png",
          ],
          "banner_title": "我們所提供的產品符合最高標準",
          "banner_desc": "品質控制團隊對每一批貨物進行嚴格的檢驗，使每一款產品的品質和性能都符合客戶的期望。"
        }
      ],
      one_container: {
        "title": "四大優勢",
        "title_yy": "四大優勢",
        "title_en": "FOUR ADVANTAGES",
        "four_info": [
          {
            "title": "價格合理",
            "desc": "擁有第一手貨源，種類齊全，客戶可以輕鬆通過官網/來電直接訂購，<span class=\"span-strong\">享受市面最佳價格</span>。"
          },
          {
            "title": "貨源穩定",
            "desc": "作為國際知名品牌和國內一線廠房的戰略合作夥伴，擁有廣泛而可靠的供應鏈，以滿足不同項目的需求。"
          },
          {
            "title": "客戶服務",
            "desc": "客戶可聯繫<span class=\"span-strong\">「私人訂料秘書」</span>下單。客服團隊具有深入的行業知識和技能，為客戶提供實用的建議。"
          },
          {
            "title": "物流配送",
            "desc": "致力於提供高效率和可靠性，以保證貨物能夠按時送達目的地，<span class=\"span-strong\">$2000起即可免運送費用（香港市區）</span>"
          }
        ],
        "four_body": {
          "mini_title": "施工材料及各種用品",
          "title": "建築材料及施工用品",
          "desc": "我們鐵木建材公司承諾為客戶提供卓越的建築材料和服務。無論您來自建築公司、房地產開發商、政府部門還是機構組織，我們都樂意成為您的合作夥伴，協助您實現建築項目的成功。我們的產品範圍廣泛，品質優良，價格合理，並提供可持續發展的建築解決方案，以滿足當今及未來的需求。請聯繫我們，讓我們為您的項目提供最佳的服務和支持。",
          "image": "https://api.genghis.hk/uploads/images/test/shutterstock_1327120316.png",
          "link": "/product",
          "link_text": "點擊查看更多材料"
        },
        "four_product": [
          {
            "id": "1",
            "title": "喉管",
            "image": "https://api.genghis.hk/uploads/images/20240515/20240515165448308034313.png"
          },
          {
            "id": "1",
            "title": "板材",
            "image": "https://api.genghis.hk/uploads/images/20240515/20240515165619281096617.png"
          },
          {
            "id": "1",
            "title": "馬路維修",
            "image": "https://api.genghis.hk/uploads/images/20240515/20240515165657d434e6827.png"
          },
          {
            "id": "1",
            "title": "鋼鐵金屬",
            "image": "https://api.genghis.hk/uploads/images/20240515/20240515165728bf4307292.png"
          },
          {
            "id": "1",
            "title": "沙泥",
            "image": "https://api.genghis.hk/uploads/images/20240515/20240515165745bdf975027.png"
          },
          {
            "id": "1",
            "title": "安全用品",
            "image": "https://api.genghis.hk/uploads/images/20240515/202405151658055929b9838.png"
          },
          {
            "id": "1",
            "title": "建築玻璃",
            "image": "https://api.genghis.hk/uploads/images/20240515/2024051516582132c049647.png"
          },
          {
            "id": "1",
            "title": "釘螺絲配件",
            "image": "https://api.genghis.hk/uploads/images/20240515/202405151658344f1b63012.png"
          },
          {
            "id": "1",
            "title": "更多其他",
            "image": "https://api.genghis.hk/uploads/images/20240515/20240515165847ad0b78967.png"
          }
        ]
      },
      tow_container: [
        {
          "image": "https://api.genghis.hk/uploads/images/test/01.png",
          "title": "智能預購",
          "title_en": "INTELLIGENT PRE-ORDER SYSTEM",
          "desc": "對於大型項目或企業週期性常用的建築材料，提前訂購可帶來可觀的成本節省。 Genghis 智能運算系統通過全面的數據分析和市場趨勢預測，為客戶提供有效合理的物料採購建議和交易方案。通過「提前預訂、分期付款和出貨」，<span class=\"title_desc\">客戶可以節省15%-20%的成本</span>，同時確保施工過程中的順利進行和項目的高效完成。",
          "mx": "通過 GENGHIS 提前訂購",
          "mx_desc": "將為你<span class=\"mx_red\">節省成本</span>",
          "number": "15%~20%",
          "link": "",
          "link_text": ""
        },
        {
          "image": "https://api.genghis.hk/uploads/images/test/02.png",
          "title": "",
          "title_en": "",
          "desc": "依據客戶的施工計劃，我們將通過智能比價系統提前預定所需的建築材料，並提供彈性的付款和出貨計劃，以確保客戶既能享受最優惠的價格，掌握更多流動資金，又能夠在最佳的時間範圍內獲得所需材料，同時避免施工過程中可能出現的物料短缺問題。通過不斷優化的供應鏈管理和服務流程，Genghis 將提供最優質的建築材料和專業的服務支持，幫助客戶降低購買和倉儲成本、提高施工效率。",
          "mx": "",
          "mx_desc": "",
          "number": "",
          "link": "/self",
          "link_text": "了解更多"
        }
      ],
      three_container: {
        image: "https://api.genghis.hk/uploads/images/test/shutterstock_1781285183.png",
        lst: [
          {
            "image": "https://api.genghis.hk/uploads/images/test/t/1.png",
            "title": "市面最低價格",
            "desc": "智能比價系統不僅可以足不出戶貨比三家，還能能夠根據市場行情變化，及時提醒客戶在價格較低時進行購買。",
            "logotype": "智能比價"
          },
          {
            "image": "https://api.genghis.hk/uploads/images/test/t/2.png",
            "title": "有助資金周轉",
            "desc": "可根據客戶的施工計劃，提供彈性的付款和出貨計劃，無需一次性支付所有貨款，減輕財務壓力。",
            "logotype": "分期付款"
          },
          {
            "image": "https://api.genghis.hk/uploads/images/test/t/3.png",
            "title": "無中間商差價",
            "desc": "擁有廣泛而可靠的供應鏈，可以滿足不同項目的需求，提供種類齊全的第一手貨源，享受更具競爭力的價格。",
            "logotype": "一手貨源"
          },
          {
            "image": "https://api.genghis.hk/uploads/images/test/t/4.png",
            "title": "節約倉儲成本",
            "desc": "依照分批配送方案，提前合理安排物流運輸的順序和時間出貨，及時提供物料運送的最新進度降低客戶倉儲成本。",
            "logotype": "分批出料"
          }
        ]
      },
      four_container: {
        "title_yy": "3TIER",
        "title": "3TIER 採購",
        "title_en": "3-TIER LOGISTICS",
        "image": "https://api.genghis.hk/uploads/images/test/kj.png",
        "lst": [
          {
            "image": "https://api.genghis.hk/uploads/images/test/shou.png",
            "title": "企業/機構",
            "desc": "享受最優惠的價格 · 最優質的建築材料 · 最完善的售後服務。"
          },
          {
            "image": "https://api.genghis.hk/uploads/images/test/che.png",
            "title": "Genghis 鐵木建材",
            "desc": "自設貨倉，貨源穩定、一手貨源、智能比價，極具競爭力的價格和專業的客戶服務。"
          },
          {
            "image": "https://api.genghis.hk/uploads/images/test/wyj.png",
            "title": "合規企業",
            "desc": "優質可靠的產品供應，減少產品運輸時間，符合進口的規範和標準，避免合規性問題。"
          },
          {
            "image": "https://api.genghis.hk/uploads/images/test/book.png",
            "title": "國際知名品牌/國內一線廠房",
            "desc": "提供種類齊全、價格實惠、符合國際標準的高品質建築材料。"
          }
        ],
        "footer": {
          "image": "https://api.genghis.hk/uploads/images/test/toher.png",
          "desc": "我們與國際領先的建材供應商和國內一線廠房合作，確保我們所提供的產品符合最高標準",
          "link_text": "了解更多",
          "link": "/quality",
          "lst": [
            {
              "title": "緊急訂貨",
              "desc": "Genghis 自設貨倉，可以提供快速供貨、緊急即送服務，可以在最短的時間內安排貨物的運送，確保客戶能夠按計劃進行施工，避免因物料供應不及時而造成的工程延誤。"
            },
            {
              "title": "一站購齊",
              "desc": "Genghis 與多家品牌生產商保持著緊密的戰略合作關係，買料無需東奔西跑，在 Genghis 即可通過智能比價挑選到性價比最高的產品，完成各類物料的採購。"
            },
            {
              "title": "智能報價",
              "desc": "即使在非工作時間，客戶也可以通過在線填寫詢價單或上傳 Excel 表格，利用 Genghis 智能運算系統，快速獲得報價方案，享受更高的工作效率和準確率，避免影響工程進度。"
            }
          ]
        },
        "ts": "節省更多時間和金錢"
      },
      five_container: {
        "image": "https://api.genghis.hk/uploads/images/test/shutterstock_1803168007.png",
        "lst": [
          {
            "image": "https://api.genghis.hk/uploads/images/test/car.png",
            "title": "· 送遞時間預測 ·",
            "desc": "可根據倉儲情況和最新的物流速度，為客戶預測送遞時間，幫助客戶制訂更加準確的施工時間表。",
            "link_text": "了解更多",
            "link": "/send"
          },
          {
            "image": "https://api.genghis.hk/uploads/images/20240515/20240515171116214d97756.png",
            "title": "· 價格浮動提醒 ·",
            "desc": "為了幫助客戶及時掌握市場價格變動情況，提供價格浮動提醒服務，讓客戶能夠及時調整採購計劃並把握最優惠的價格。",
            "link_text": "了解更多",
            "link": "/self"
          },
          {
            "image": "https://api.genghis.hk/uploads/images/20240515/20240515171133a8cdf0728.png",
            "title": "· 物料採購建議 ·",
            "desc": "通過記錄客戶的訂單資料，我們將進行全面的數據運算和分析，根據項目需求和施工進度，為客戶提供個性化的物料採購建議。",
            "link_text": "了解更多",
            "link": "/self"
          }
        ]
      }
    };
  },
  created() {
 
  },
  methods: {
    onMouseleave() {
      var that = this;
      var banner = that.$refs.banner_box.getElementsByClassName("carousel_diy_item");
      for (let index = 0; index < banner.length; index++) {
        banner[index].style.animationPlayState = "running";
      }
    },
    onMouseenter() {
      var that = this;

      var banner = that.$refs.banner_box.getElementsByClassName("carousel_diy_item");

      for (let index = 0; index < banner.length; index++) {
        banner[index].style.animationPlayState = "paused";
      }
     
    }
  }
};
</script>
<style>
.span-strong {
  font-weight: bold;
}

.title_desc {
  color: #00574c;
}

.mx_red {
  color: #ff0000;
}
</style>
<style lang="scss" scoped>
.banner{
  display: flex;
    width: 100%;
  overflow: hidden;
}
.carousel-diy-box {
  display: flex;
  flex-flow: nowrap;

  position: relative;
}

.carousel_diy_item {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: nowrap;
  animation: rolling 15s linear infinite;
  // animation-play-state: paused;
}

.four_container_foorer {
  margin-top: 50px;
  background: #f1f1f1;
  padding: 50px 40px;
  display: flex;
  position: relative;

  .four_container_foorer_right {
    .ts {
      position: absolute;
      right: -45px;
      bottom: -30px;
      font-size: 47px;
      color: #d9d9d9;
      font-weight: bold;
      letter-spacing: 5px;
    }

    display: flex;
    position: relative;
    flex-direction: column;

    .four_container_foorer_right_item {
      border-bottom: 1px solid #00574c;
      display: flex;
      margin-left: 50px;
      margin-bottom: 35px;
      padding-bottom: 10px;

      .title {
        width: 95px !important;
        height: 95px !important;
        color: #fff;
        font-weight: bold;
        font-size: 32px;
        text-align: center;
        background-color: #002557;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 38px;
      }

      .desc {
        flex: 1;
        margin-left: 30px;
        font-size: 15px;
        color: #000;
        line-height: 28px;
        letter-spacing: 1px;
      }
    }

    .four_container_foorer_right_item:last-child {
      border-bottom: none;
    }
  }

  .four_container_foorer_left {
    width: 650px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .four_container_foorer_left_img {
      width: 540px;
    }

    .desc {
      text-align: center;
      margin-top: 20px;
      font-size: 12px;
      letter-spacing: 1.5px;
    }

    .btn {
      border: 1px solid #b8b8b8;
      padding: 5px 15px;
      color: #002557;
      margin-top: 25px;
      letter-spacing: 1.5px;
    }

    .btn:hover {
      background: #002557 !important;
      color: #fff !important;
      border: 1px solid #002557;
      transition: all 0.3s ease;

    }

  }

  .index_last {
    position: absolute;
    bottom: 30px;
    right: 5px;
    font-family: HarmonyOS_Sans_SC_Bold;
    font-weight: 400;
    font-size: 45px;
    color: #d9d9d9;
    letter-spacing: 2px;
  }
}

.four_container_body {
  display: flex;
  justify-content: center;
  margin-left: 80px;

  .four_container_image {
    width: 460px;
    height: 346px;
  }

  .four_container_lst {
    margin-left: 35px;
    flex: 1;

    .four_container_lst_item {
      margin-bottom: 20px;

      .title {
        display: flex;
        align-items: center;

        span {
          padding: 8px 20px;
          display: inline-block;
          color: #fff;
        }

        img {
          margin-left: 10px;
          width: 20px;
          height: 20px;
        }
      }

      .desc {
        margin-top: 10px;
        color: #000000;
        letter-spacing: 1.5px;
      }
    }

    .four_container_lst_item:nth-child(2n) {
      .title {
        span {
          background: #002557;
        }
      }
    }

    .four_container_lst_item:nth-child(2n + 1) {
      .title {
        span {
          background: #252d2f;
        }
      }
    }
  }
}

.three_container_item_image_logotype {
  width: 260px;
  height: 260px;
  // position: absolute;

  // position: relative;
}

.three_container_item_logotype {
  // position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  flex-wrap: wrap;
  position: relative;
  left: 190px;
  top: 69px;
  z-index: 50;
  color: #fff;
  font-size: 20px;
  padding: 10px;
  padding-right: 8px;
  line-height: 25px;
  border-bottom-left-radius: 30%;
  font-weight: 800;
  text-align: center;

  background-color: #ce364b;
}

.three_container {
  padding-top: 30px;
  background: #ffffff;

  .three_container_img {
    height: 550px;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;

    .three_container_container {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .three_container_item {
        display: flex;
        flex-direction: column;

        .three_container_item_image {
          width: 260px;
          height: 260px;
        }

        .three_container_item_info {
          background: #002557;
          color: #fff;
          text-align: center;
          width: 260px;
          padding: 20px;
          border-left: 1px solid #002557;
          border-bottom: 1px solid #002557;
          border-right: 1px solid #002557;

          .title {
            font-weight: bold;
            font-size: 18px;
          }

          .line {
            margin-top: 20px;
            margin-bottom: 10px;
            display: inline-block;
            width: 50px;
            background: #fff;
            height: 2px;
          }

          .desc {
            letter-spacing: 2px;
            font-size: 14px;
            line-height: 20px;
          }
        }

        .three_container_item_info:hover {
          background: #fff;
          color: #002557;
          border-left: 1px solid #002557;
          border-bottom: 1px solid #002557;
          border-right: 1px solid #002557;

          transition: all 0.3s ease;
        }
      }
    }

    .five_container_container {
      align-items: flex-start !important;
      justify-content: space-between !important;
      padding-top: 50px;

      .five_container_item {
        position: relative;

        .five_container_item_image {
          width: 400px !important;
          height: 280px !important;
        }

        .five_container_item_info {
          width: 350px;
          position: absolute;
          bottom: -100px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #fff !important;
          color: #000 !important;
          z-index: 10;

          .line {
            width: 200px !important;
            background: #dbdbdb !important;
            position: relative;
          }

          .line::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 4px;
            width: 60px;
            background-color: #002557;
          }

          .btn {
            background: #002557;
            color: #ffffff;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 2px;
            display: inline-block;
            position: absolute;
            height: 30px;
            width: 100px;
            text-align: center;
            left: 50%;
            transform: translate(-50%);
            bottom: -15px;
          }
        }
      }
    }
  }
}

.hs {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;

  .div_one {
    display: flex;
    flex-direction: column;
    align-items: end;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
  }

  .hz_zk {
    padding-left: 20px;
    color: #ff0000;
    font-size: 54px;
    font-family: CityD-Medi;
  }
}

.two_container_item {
  display: flex;
  flex-direction: column;

  .tow_container_item {
    display: flex;

    .tow_container_item_desc {
      flex: 1;
      padding: 50px;
      box-sizing: border-box;
      font-size: 16px;
      margin-top: 10px;
      line-height: 25px;
      letter-spacing: 1px;
      color: #000;
      font-weight: 400;
      position: relative;

      .btn {
        background: #002557;
        color: #ffffff;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 2px;
        padding: 10px 20px;
        margin-top: 50px;
        display: inline-block;
      }
    }

    .tow_container_item_desc::after {
      content: "";
      position: absolute;
      right: -35px;
      top: 50px;
      width: 0;
      height: 0;
      border-bottom: 35px solid transparent;
      border-top: 35px solid transparent;
      border-left: 70px solid #f1f1f1;
    }

    img {
      width: 50%;
    }

    .tow_container_one_info {
      flex: 1;
    }

    .tow_container_one_info::after {
      content: "";
      position: absolute;
      left: -35px;
      top: 50px;
      width: 0;
      height: 0;
      border-bottom: 35px solid transparent;
      border-top: 35px solid transparent;
      border-right: 70px solid #f1f1f1;
    }

    .tow_container_one_info {
      padding: 30px;
      position: relative;
      padding-left: 80px;
      box-sizing: border-box;

      .tow_container_one_info_title {
        color: #000000;
        font-weight: bold;
        font-size: 28px;

        span {
          font-weight: initial;
          padding-left: 10px;
          font-size: 14px;
        }
      }

      .line {
        display: inline-block;
        width: 280px;
        background: #00574c;
        height: 1px;
      }

      .desc {
        font-size: 16px;
        margin-top: 10px;
        line-height: 25px;
        letter-spacing: 1px;
        color: #000;
        font-weight: 400;
      }
    }
  }
}

.one_container_product {
  display: flex;
  margin-bottom: 30px;

  .product:hover {
    .product_title {
      background-color: #002557;
      color: #fff;
      transition: all 0.3s ease;
    }
  }

  .product {
    margin-right: 5px;
    flex: 1;

    .product_title {
      background-color: #f1f1f1;
      color: #000000;
      letter-spacing: 2px;
      text-align: center;
      line-height: 30px;
    }

    .product_image {
      width: 100%;
    }
  }

  .product:last-child {
    margin-right: 0;
  }
}

.one_container_body {
  display: flex;
  margin-bottom: 8px;

  .one_container_body_info {
    flex: 1;
    background-color: #f1f1f1;
    padding: 50px;
    box-sizing: border-box;

    .mini_title {
      font-size: 14px;
      color: #000000;
      margin-bottom: 20px;
    }

    .title {
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 30px;
    }

    .line {
      width: 165px;
      height: 1px;
      background: #000000;
      display: inline-block;
      margin-bottom: 15px;
    }

    .desc {
      font-size: 14px;
      margin-bottom: 30px;
      line-height: 25px;
      letter-spacing: 2px;
    }

    .btn {
      background: #002557;
      color: #ffffff;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 4px;
      padding: 10px 20px;
    }
  }

  .one_container_body_image {
    flex: 1;
  }
}

.four_info {
  display: flex;
  margin-bottom: 20px;

  .four_info_item {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
    letter-spacing: -1px !important;

    .four_info_container:hover {
      background: #fff;
      color: #002557;
      border: 1px solid #002557;

      .four_info_container_title {
        color: #002557;

        span {
          border-bottom: 2px solid #002557;
        }
      }

      .four_info_container_desc {
        color: #002557;
      }
    }

    .four_info_container {
      background: #002557;
      padding: 20px;
      display: flex;
      flex-direction: column;
      border: 1px solid #002557;

      .four_info_container_title {
        text-align: left;
        font-family: HarmonyOS_Sans_SC_Bold;
        font-weight: bold;
        font-size: 18px;
        color: #ffffff;

        span {
          padding-bottom: 5px;
          border-bottom: 2px solid #fff;
        }
      }

      .four_info_container_desc {
        margin-top: 10px;
        color: #fff;
        line-height: 25px;
        font-size: 14px;
      }
    }
  }

  .four_info_item:nth-child(1) {
    padding-left: 0;
  }

  .four_info_item:last-child {
    padding-right: 0;
  }
}

.container_item {
  margin-top: 50px;

  .container_item_title {
    width: 600px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    border-bottom: 1px solid #c0c0c0;
    padding-bottom: 15px;
    margin-bottom: 20px;

    .title_en {
      font-family: HarmonyOS_Sans_SC_Light;
      font-size: 22px;
      color: #000000;
      margin-bottom: 15px;
    }

    .title_zn {
      font-size: 20px;
      font-weight: bold;
      color: #002557;
      position: relative;
    }

    .title_zn::after {
      content: "";
      position: absolute;
      bottom: -12px;
      left: 15%;
      width: 70%;
      background: #002557;
      height: 3px;
    }

    .title_yy {
      width: 400px;
      height: 100px;
      position: absolute;
      z-index: -1;
      font-size: 50px;
      left: 50%;
      top: 10%;
      transform: translate(-50%, -50%);
      opacity: 0.8;
      letter-spacing: 40px;
      color: #eeeeee;
    }
  }
}

.item_img {
  padding: 2px;
  box-sizing: border-box;
  height: auto;
}

.banner_title {
  position: absolute;
  height: 100px;
  bottom: 0;
  left: 0;
  background: rgba(#002557, 0.6);
  width: 100%;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-family: HarmonyOS_Sans_SC_Bold;
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  span {
    font-family: HarmonyOS_Sans_SC_Light;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
  }
}

.banner {
  margin-bottom: 20px;
  position: relative;
}

@keyframes rolling {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.btn:hover {
  background: #fff !important;
  color: #002557 !important;
  border: 1px solid #002557;
  transition: all 0.3s ease;
}

.btn {
  border: 1px solid #002557;
}
</style>
