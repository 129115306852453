<template>
  <div class="link_config">
    <div class="link" @mouseleave="onMouseleaveLink()" @mouseenter="onMouseenterLink()">
      
      <div class="link_item_wapp" >
      <a :href="'https://api.whatsapp.com/send/?phone='+header_config.whatapp+'&text&type=phone_number&app_absent=0'" target="_blank"><img class="item_icon_wapp" src="@/assets/icon/whatsapp.png" /></a>
      </div>
    </div>
    <div class="link_item_top" @click="toTop()" v-if="is_top">
      <a href="#top" target="_self"><img class="item_icon_top" src="@/assets/icon/top.png" /></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: "",
      // link_config: "",
      accountShow: false,
      account: "",
      linkType: "",
      accountIndex: "",
      is_hover: false,
      is_top: false,
      scrollTop: window.pageYOffset,
      header_config: {
        title: "香港鐵木建材有限公司自成立以來，一直為廣大客戶提供安全、可靠、優質的建築材料及施工用品",
        pc_logo: "https://api.genghis.hk/uploads/images/20240511/202405112219342e29f6744.png",
        phone: "+852 6213 9561",
        email: "Info@genghis.hk",
        whatapp:"85262139561"
      },
    };
  },
   mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
    });
  },
  created() {
  
    var user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
    }
    // const header_config = JSON.parse(localStorage.getItem("header_config"));
    // this.header_config = header_config;
    this.handleScroll();
  },

  watch: {
    scrollTop(newVal, oldVal) {
      this.handleScroll();
    }
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop > 500) {
        //设置页面滑动多少显示按钮
        this.is_top = true;
      } else {
        this.is_top = false;
      }
    },
    toTop() {},
    onMouseenter(item, index) {
      // "item_icon"+index
      var that = this;
      that.linkType = item.type;
      that.accountIndex = index;

      // for (var j = 0; j < that.$refs.item_icon.length; j++) {
      //   if (j == index) {
      //     that.$refs.item_icon[j].style.height = "50px";
      //     that.$refs.item_icon[j].style.width = "50px";
      //   } else {
      //     that.$refs.item_icon[j].style.height = "40px";
      //     that.$refs.item_icon[j].style.width = "40px";
      //   }
      // }
      if (item.type) {
        that.accountShow = true;
        that.account = item.account;
      }
    },
    onMouseenterLink() {
      var that = this;
      that.is_hover = true; //悬停
    },
    onMouseleaveLink() {
      var that = this;
      that.is_hover = false; //离开

      setTimeout(() => {
        // 方法区
        if (!that.is_hover) {
          that.accountShow = false;
        }
      }, 5000);

      // if (index == that.accountIndex) {

      //
      //   setTimeout(() => {
      //     // 方法区
      //     that.accountShow = false;

      //     // that.$refs.item_icon[index].style.height = "40px";
      //     // that.$refs.item_icon[index].style.width = "40px";
      //   }, 5000);
      // }
    },
    copyToClip() {
      //创建input标签
      var input = document.createElement("input");
      //将input的值设置为需要复制的内容
      input.value = this.account;
      //添加input标签
      document.body.appendChild(input);
      //选中input标签
      input.select();
      //执行复制
      document.execCommand("copy");
    }
  }
};
</script>

<style lang="scss" scoped>
.link_config {
  z-index: 10000;

  position: fixed;
  bottom: 10%;
  right: 0;
}
.link {
  position: relative;

  .link_item {
    padding: 5px;
    cursor: pointer;
  }
  .item_icon {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
  }
}
.link_item_top {
  padding: 10px 5px;
  cursor: pointer;
  .item_icon_top {
    width: 45px;
    height: 45px;
    background: white;
    border-radius: 50%;
  }
}
.link_item_wapp {
  padding: 10px 5px;
  cursor: pointer;
  .item_icon_wapp {
    width: 45px;
    height: 45px;
    background-color: white;
    border-radius: 10%;
  }
}
// .link_item {
//   padding: 5px;
//   cursor: pointer;
// }
// .item_icon {
//   width: 40px;
//   height: 40px;
//   background: #fff;
//   border-radius: 50%;
// }
.accountBox {
  z-index: 500;
  position: absolute;
  top: 30px;
  right: 60px;
  width: 200px;
  //   height: 80px;
  padding: 5px;
  //   border: 2px solid #fff;
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: space-around;
  align-items: center;
  background-color: #2e4f78;
  .accountText {
    padding: 5px 0;
  }

  .copy_btns {
    margin: 5px 10px;
    padding: 5px 10px;

    justify-self: flex-end;
    align-self: flex-end;
    background-color: #fff;
    color: #2e4f78;
    border-radius: 20px;
    cursor: pointer;
  }
  .account_image {
    width: 100px;
    height: 100px;
    padding: 10px;
  }
}
</style>

<style lang="scss">
.link_config {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
