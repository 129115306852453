<template>
  <div>
    <appHeader></appHeader>
    <appNav></appNav>

    <div class="login_box">

      <div class="user_info">
        <div class="user_info_item" :class="active == 'user' ? 'user_info_active' : ''" @click="active = 'user'">個人信息
        </div>
        <div class="user_info_item" :class="active == 'order' ? 'user_info_active' : ''" @click="active = 'order'">我的訂單
        </div>
        <div class="user_info_item" :class="active == 'address' ? 'user_info_active' : ''" @click="active = 'address'">
          收穫地址
        </div>
      </div>
      <div class="user_right">
        <div v-if="active == 'user'">
          <div class="from_item">
            <div class="from_item_title">
              用戶名
              <span>*</span>
            </div>
            <el-input placeholder="" v-model="user.nickname"></el-input>
          </div>

          <div class="from_item">
            <div class="from_item_title">
              聯係電話
              <span>*</span>
            </div>
            <el-input placeholder="" v-model="user.mobile"></el-input>
          </div>
          <div class="from_item">
            <div class="from_item_title">
              電子郵箱
              <span>*</span>
            </div>
            <el-input placeholder="" disabled v-model="user.email"></el-input>
          </div>

          <div class="from_item">
            <el-button class="login_btn" @click="registerFrom" :disabled="disres">修改</el-button>
          </div>
          <div class="from_footer">
            <a class="aflex" href="/forgot_email">修改郵箱</a>
            <a class="aflex" href="/forgot">修改密碼</a>
          </div>
        </div>
        <div v-if="active == 'order'">
          <div class="order-item-list">
            <div class="order-item" v-for="(item, index) in orderList">
              <div class="order_title">
                <span style="flex: 1; text-align: left;">訂單編碼：{{ item.order_sn }}</span>

                <span style="flex: 1;text-align: right;">下單時間：{{ item.create_time }}</span>
              </div>
              <div class="order-item-info">
                <div class="item-info" v-for="(info, ind) in item.orderItem">
                  <img :src="info.sku_image" class="sku_image">
                  <div class="info-box">
                    <div class="product-info">
                      {{ info.product_title }}
                      <br />
                      <br />
                      {{ info.product_sku }}
                    </div>
                    <div class="product_number">
                      數量： {{ info.product_number }}
                    </div>
                    <!-- <div class="product_price" v-if="info.productInfo.product_price == info.productInfo.max_product_price">
                      价格：${{ info.productInfo.product_price }} 
                    </div>
                    <div class="product_price" v-else>
                      价格：${{ info.productInfo.product_price }} ~ {{ info.productInfo.max_product_price }}
                    </div> -->
                    <div class="total_price">
                      备注:{{ info.remake }}
                    </div>

                  </div>
                  <div class="total_price">
                    <span style="color: #19be6b" v-if="info.aftersales ==0 && item.order_state == 1">平臺確認中</span>
                    <div v-else-if="item.order_state == 2 && info.aftersales ==0">
                      <span style="color: #57a3f3" v-if="item.lingdao_state == 2 && item.xiaoshou_state == 2 && item.laoban_state == 2">
                        <span style="color: #19be6b" v-if="info.deliver_type ==1 ">等待發貨</span>
                        <span style="color: #f16643" v-if="info.deliver_type ==2 ">已發貨</span>
                        <span style="color: #57a3f3" v-if="info.deliver_type ==3 ">已收貨</span>
                      </span>
                      <span style="color: #57a3f3" v-else>
                        <span style="color: #19be6b" v-if="info.deliver_type ==1 ">等待發貨</span>
                      </span>
                    </div>
                    <span  style="color: #f16643" v-if="info.aftersales == 1">售後完成</span>
                  </div>
                </div>
              </div>

              <!-- <div class="order-price">訂單總價：${{ item.total_price }}</div> -->
              <hr>
            </div>
          </div>
          <el-pagination small :page-size="10" layout="prev, pager, next" :total="orderCount"
            @current-change="handleCurrentChange"></el-pagination>
        </div>
        <div v-if="active == 'address'">
          <div class="address_box">
            <el-form ref="form" :model="address" :inline="true" label-width="80px" class="address">
              <div class="address_one">
                <el-form-item label="收貨人">
                  <el-input v-model="address.contact_person" style="width: 270px;"></el-input>
                </el-form-item>
                <el-form-item label="收貨電話">
                  <el-input v-model="address.contact_phone" style="width: 270px;"></el-input>
                </el-form-item>
                <el-form-item label="聯係郵箱">
                  <el-input v-model="address.contact_email" style="width: 270px;"></el-input>
                </el-form-item>
              </div>
              <div class="address_two">
                <el-form-item label="收穫地址">
                  <el-cascader ref="cascaderRef" @change="selectAddress" :props="{ value: 'area_code', label: 'title' }"
                    clearable filterable :options="addressInfo" v-model="addressModelValue" style="width: 450px">
                  </el-cascader>
                </el-form-item>
              </div>
              <div class="address_two">
                <el-form-item label="詳細地址">
                  <el-input type="textarea" v-model="address.address_info" style="width: 270px;"></el-input>
                </el-form-item>
                <el-form-item label="備注">
                  <el-input type="textarea" v-model="address.remake" style="width: 270px;"></el-input>
                </el-form-item>
                <el-form-item label="" style="width: 270px;">
                </el-form-item>
              </div>
            </el-form>
            <div class="from_item">
              <el-button class="login_btn" @click="saveAddress">保存地址</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <appFooter></appFooter>
  </div>
</template>
<script>
import appHeader from "@/components/header.vue";
import appFooter from "@/components/footer.vue";
import appNav from "@/components/nav.vue";
import { saveUserAddressApi, getUserInfoApi, editUserInfoApi, orderListApi, userAddressApi } from "@/api/index.js";
import { validatePhoneNumber } from "@/utils/phone.js";
import AreaJson from "@/json/area.json";

export default {
  components: {
    appHeader,
    appFooter,
    appNav
  },
  data() {
    return {
      addressInfo: AreaJson,
      user: {

        nickname: "",
        mobile: "",
        email: ""
      },
      disres: false,
      active: "order",
      page: 1,
      orderList: [],
      orderCount: 0,
      address: {
        id: "",
        address_info: "",
        contact_email: "",
        contact_phone: "",
        contact_person: "",
        remake: "",
        province_name: "",
        province_id: "",
        city_name: "",
        city_id: "",
        area_name: "",
        area_id: "",
      },
      addressModelValue: []
    };
  },
  created() {
    var token = localStorage.getItem("token");
    if (token) {
      this.getUserInfo();
      this.getOrderList();
      this.userAddress();
    } else {
      this.$router.push({ path: "/login" });
    }
  },
  mounted() { },
  methods: {
    saveAddress() {
      var that = this;
      var address = that.address;
      if (address.contact_person == "") {
        this.$message.error("收貨人不能爲空");
        return;
      }
      if (address.contact_phone == "") {
        this.$message.error("收貨電話不能爲空");
        return;
      }
      if (address.contact_email == "") {
        this.$message.error("聯係郵箱不能爲空");
        return;
      }
      if (address.province_name == "" || address.city_name == "" || address.area_name == "") {
        that.$message.error("请完善收貨地址信息");
        return;
      }
      if (address.address_info == "") {
        this.$message.error("詳細地址不能爲空");
        return;
      }
      saveUserAddressApi(address).then(res => {
        if (res.code == 200) {
          that.$message.success(res.msg);
        } else {
          that.$message.error(res.msg);

        }
      })
    },
    selectAddress(address_info) {
      console.log(address_info)
      this.address.province_id = address_info[0];
      this.address.city_id = address_info[1];
      this.address.area_id = address_info[2];

      var city_label = [];
      AreaJson.forEach((item, index) => {
        if (item.area_code == address_info[0]) {
          city_label[0] = item.title;
          item.children.forEach((city, ind) => {
            if (city.area_code == address_info[1]) {
              city_label[1] = city.title;
              city.children.forEach((area, ins) => {
                if (area.area_code == address_info[2]) {
                  city_label[2] = area.title;
                }
              })
            }
          })
        }
      });
      this.address.province_name = city_label[0];
      this.address.city_name = city_label[1];
      this.address.area_name = city_label[2];
    },
    userAddress() {
      var that = this;
      userAddressApi().then((res) => {
        that.address = res.data;
        that.addressModelValue = [res.data.province_id, res.data.city_id, res.data.area_id]
      });
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getOrderList();
    },

    getOrderList() {
      var that = this;
      orderListApi({
        page: that.page
      }).then(res => {
        console.log(res)
        that.orderList = res.data.list;
        that.orderCount = res.data.count;
      })
    },
    getUserInfo() {
      var that = this;
      getUserInfoApi()
        .then((res) => {
          var res = res.data;
          that.user = res;
        })
        .catch((res) => {
          that.$message.error(res.msg);
        });
    },
    registerFrom() {

      if (this.user.nickname == "") {
        this.$message.error("用戶名不能爲空");
        return;
      }
      if (this.user.mobile == "") {
        this.$message.error("聯係電話不能爲空");
        return;
      }
      if (this.user.email == "") {
        this.$message.error("郵箱不能爲空");
        return;
      }

      //郵箱校驗
      //手机号码校驗

      var mobile_reg = validatePhoneNumber(this.user.mobile);

      if (!mobile_reg) {
        that.$message.error("请輸入正確手機號碼");
        return;
      }

      this.disres = true;
      editUserInfoApi(this.user).then((res) => {
        this.disres = false;
        if (res.code == 200) {
          this.$message.success("修改成功");
          var token = localStorage.getItem("token");
          if (token) {
            this.getUserInfo(true);
          } else {
            this.$router.push({ path: "/login" });
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.from_footer {
  margin-top: 20px;
  display: flex;
  justify-content: end;

  .aflex {
    margin-left: 40px;
    color: #2e4f76;
  }
}

.login_btn {
  width: 100%;
  height: 45px;
  background: #2e4f76;
  border-radius: 17px;
  color: #fff;
}

.login_btn:hover,
.login_btn:active,
.login_btn:focus {
  background: #2e4f76;
  color: #fff;
}

.from_item {
  margin-bottom: 30px;
}

.from_item_title {
  margin-bottom: 20px;

  span {
    color: red;
  }
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  color: #2e4f76;
  line-height: 150px;
}

.login_box {
  padding: 50px 80px;
  width: 1176px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 2px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  border: 1px solid #464646;
  margin: 100px auto;
  display: flex;
}

.user_info {
  width: 100px;
}

.user_info_item {
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user_info_active {
  background: #23436e;
  color: #fff;
}

.user_info_item:hover {
  background: #23436e;
  color: #fff;
}

.user_right {
  flex: 1;
  padding-top: 15px;
  padding-left: 50px;
}

.order_title {
  display: flex;
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
}

.order-item {
  margin-bottom: 10px;
  padding-top: 10px;
}

.info-box {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.product-info {
  flex: 1;
  font-size: 12px;
  color: #999;
  padding-left: 20px;
}

.product_number,
.product_price,
.total_price {
  width: 150px;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.item-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.sku_image {
  width: 50px;
  height: 50px;
}

.order-price {
  margin-bottom: 20px;
  text-align: right;
  font-size: 14px;
}

.address_box {
  background: #ffffff;
  border-radius: 5px;

  .title {
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 24px;
    color: #2e4f76;
  }

  .address {
    padding: 30px 0;
    padding-top: 0px;

    .address_one {
      display: flex;
      justify-content: space-between;
    }

    .address_two {
      display: flex;
      justify-content: space-between;
    }

  }
}
</style>
