import axios from "axios";
import Setting from "@/setting.js";

// 创建一个 axios 实例
const service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 30000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    var token =   localStorage.getItem("token");
    if(token){
      config.headers["Authori-zation"] = "Bearer " + token;
    }
    console.log(config)
    return config;
  },
  (error) => {
    // 发送失败

    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const code = response.data.code;
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    switch (code) {
      case 200:
        // [ 示例 ] code === 1 代表没有错误
        return response.data;
        case 4001:
          window.location.href = "/login"
      default :
        // 不是正确的 code
        return response.data;
    }
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
